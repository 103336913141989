import React, { useState } from "react";
import "./loginModal.scss";
import { Button } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import Register from "../register/Register";
import Login from "../login/Login";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const LoginModal = ({ open, openModal, closeModal }) => {
  const [loginPage, setLoginPage] = useState(true);

  const changePage = () => {
    setLoginPage(!loginPage);
  };

  return (
    <div className="loginModal">
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={closeModal}
        aria-describedby="alert-dialog-slide-description"
      >
        {loginPage && <Login changePage={changePage} close={closeModal} />}
        {!loginPage && <Register changePage={changePage} close={closeModal} />}
      </Dialog>
    </div>
  );
};

export default LoginModal;
