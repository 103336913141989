import { Link, useLocation, useParams } from "react-router-dom";
import "./menuList.scss";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import GetText from "../../functions/getTexts";

const MenuList = ({ list, mwidth }) => {
  const location = useLocation().pathname.split("/")[1];
  const [index, setIndex] = useState(0);

  useEffect(() => {
    setIndex(0);
  }, [location]);

  const handleClick = (i) => {
    console.log(i, "in news");
    setIndex(i);
  };

  const link = useLocation().pathname.split("/")[1];
  const topic = useParams();
  const { dir } = GetText("room");
  const lan = useTranslation().i18n.language;
  return (
    <div className={dir ? "menuList" : "menuList-ltr"}>
      <div className="mlContainer" style={{ maxWidth: mwidth && "100%" }}>
        <ul>
          {list.map((item, i) => {
            return (
              <Link
                onClick={() => handleClick(i)}
                to={`/${link}/${item.articleId}`}
                state={item.topic[lan]}
                key={i}
              >
                <li
                  key={i}
                  style={{
                    backgroundColor: index == i && "orange",
                    color: index == i && "white",
                  }}
                >
                  {item.topic[lan]}
                </li>
              </Link>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default MenuList;
