export const navbarList = [
  {
    title: "خانه",
    link: "/",
  },
  {
    title: "محصولات",
    link: "/products",
  },
  {
    title: "درباره ی عسل",
    link: "/abouthoney/abouthoney",
  },

  {
    title: "زنبور ها و پایداری",
    link: "/bees-sustainability/sustainability",
  },
  {
    title: "دستور پخت",
    link: "/recipes",
  },
  {
    title: "مکان یاب",
    link: "/locator",
  },
  {
    title: "درباره ی ما",
    link: "/aboutus",
  },
];

export const sliderDate = [
  {
    img: "/images/slider1.jpg",
    desc: "عسل های تازه",
  },
  {
    img: "/images/slider2.jpg",
    desc: "عسل های تازه",
  },
  {
    img: "/images/slider3.png",
    desc: "عسل های تازه",
  },
  {
    img: "/images/slider4.jpg",
    desc: "عسل های تازه",
  },
  {
    img: "/images/slider5.jpg",
    desc: "عسل یک دستور غذای تک ماده ای است که توسط زنبورها، گل ها و خود مادر طبیعت درست می شود. های تازه",
  },
  {
    img: "/images/slider6.webp",
    desc: "عسل های تازه",
  },
  {
    img: "/images/slider2.jpg",
    desc: "عسل های تازه",
  },
  {
    img: "/images/slider2.jpg",
    desc: "عسل های تازه",
  },
  {
    img: "/images/slider2.jpg",
    desc: "عسل های ",
  },
];

export const sustabilityNavbar = [
  {
    title: "زنبورها و پایداری",
    link: "/bees-sustainability/sustainability",
  },
  {
    title: "سلامت زنبور عسل",
    link: "/bees-sustainability/bee-health",
  },
  {
    title: "پرورش گیاه",
    link: "/bees-sustainability/nurturing-the-planet",
  },
  {
    title: "داخل کندو",
    link: "/bees-sustainability/inside-the-hive",
  },
  {
    title: "زنبورها و گرده افشانی",
    link: "/bees-sustainability/honey-bees-pollination",
  },
  {
    title: "کمک به زنبورها",
    link: "/bees-sustainability/help-the-bees",
  },
  {
    title: "مواد آموزشی",
    link: "/bees-sustainability/educational-matrials",
  },
];
