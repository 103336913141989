import "./videos.scss";
import Navbar from "../navbar/Navbar";
import Footer from "../footer/Footer";
import ImgBanner from "../ImgBanner/ImgBanner";
import MenuList from "../menuList/MenuList";
import ExampleSlider from "../exampleSlider/ExampleSlider";
import GenerateSlider from "../gallerySlider/GenerateSlider";
import VideoPlayer from "../videoPlyer/VideoPlayer";
import { falist, enlist } from "../../data/list";
import GetText from "../../functions/getTexts";

const Videos = () => {
  const { dir, menu, videos } = GetText("room");
  return (
    <>
      <div>
        <ImgBanner src={"/images/flower2.jpg"} />
        <MenuList list={menu} />
        <div className={dir ? "videos" : "videos-ltr"}>
          <div className="vContainer">
            <h1 className="title">{videos.title}</h1>
            <h2>{videos.subTitle}</h2>
            <p>{videos.text}</p>
            <h3 className="h3">{videos.sliders[0]}</h3>
            <GenerateSlider items={[]} width={"100%"} />
            <h3 className="h3">{videos.sliders[1]}</h3>
            <GenerateSlider items={[]} width={"100%"} />
            <h3 className="h3">{videos.sliders[2]}</h3>
            <GenerateSlider items={[]} width={"100%"} />
          </div>
        </div>
      </div>
      <VideoPlayer />
    </>
  );
};

export default Videos;
