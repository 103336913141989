import "./pay.scss";
import { Link, redirect, useNavigate, Navigate } from "react-router-dom";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import { useState } from "react";
import { useSelector } from "react-redux";
import CircularIndeterminate from "../../componnts/circleProgress/CircleProgress";
import { publicRequest } from "../../functions/request";
import { useTranslation } from "react-i18next";
import { checkFormFull } from "./data";
import { toast } from "react-toastify";
import { handleError } from "../../functions/errorHandling";
import GetText from "../../functions/getTexts";

const Pay = () => {
  const [link, setLink] = useState(null);
  const [isFetc, setIsFetch] = useState(false);
  const currentUser = useSelector((state) => state.user.currentUser);
  const [des, setDes] = useState("");
  const [customer, setCustomer] = useState({
    phone: currentUser.phone,
    name: "",
    address: "",
  });

  const cart = useSelector((state) => state.cart);
  const fullTotla = cart.products
    .map((p) => p.number * p.price)
    .reduce((a, b) => a + b);

  const { products, total } = cart;
  const handleChange = (e) => {
    setCustomer((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const lan = useTranslation().i18n.language;

  const { pay, custommerDetails, payDetails, gotopay, dir } = GetText("pay");

  const handleNewOrd = async () => {
    const ordProducts = products.map((p) => ({
      productId: p._id,
      name: p.name[lan],
      img: p.img[lan],
      discount: p.disCount,
      description: p.description[lan],
      price: p.price,
      number: p.number,
    }));
    const newOrder = {
      userId: currentUser._id,
      customerDetails: {
        address: customer.address,
        name: customer.name,
        phone: customer.phone,
      },
      products: ordProducts,
      amount: total,
      description: des,
    };
    try {
      const res = await publicRequest.post("/order", newOrder);
      return res.data;
    } catch (error) {
      handleError(error);
    }
  };

  const navigate = useNavigate();

  const startPay = async () => {
    setIsFetch(true);
    const order = await handleNewOrd();
    const payment = {
      orderId: order._id,
      amount: order.amount,
    };
    try {
      const res = await publicRequest.post("/pay", payment);
      console.log(res.data, "RES");
      setIsFetch(false);
      setLink(res.data);
    } catch (error) {
      console.log(error, "ERROR");
      handleError(error);
    }
  };

  const handlePay = async () => {
    checkFormFull(customer)
      ? startPay()
      : toast.error("اطلاعات گیرنده سفارش را تکمیل نمایید!");
  };

  return (
    <div className={dir ? "pay" : "pay-ltr"}>
      <div className="payContainer">
        <div className="navbarPay">
          <h1> {pay}</h1>
          <Link to={"/"}>
            <HomeOutlinedIcon fontSize="large" className="homeIcon" />
          </Link>
        </div>
        <div className="content">
          <div className="rightCon">
            <h2> {custommerDetails.title} </h2>
            <div className="details">
              <div className="ditem">
                <span>{custommerDetails.phone.title}</span>
                <input
                  type="text"
                  name="phone"
                  value={customer.phone}
                  onChange={(e) => handleChange(e)}
                />
              </div>
              <div className="ditem">
                <span>{custommerDetails.name.title}</span>
                <input
                  type="text"
                  name="name"
                  value={currentUser.name}
                  onChange={(e) => handleChange(e)}
                  placeholder={custommerDetails.name.place}
                />
              </div>
              <div className="ditem">
                <span> {custommerDetails.address.title}</span>
                <input
                  type="text"
                  name="address"
                  value={customer.address}
                  onChange={(e) => handleChange(e)}
                  placeholder={custommerDetails.address.place}
                />
              </div>
              <div className="ditem">
                <span>{custommerDetails.description.title}</span>
                <textarea
                  type="text"
                  name="des"
                  value={des}
                  onChange={(e) => setDes(e.target.value)}
                  placeholder={custommerDetails.description.place}
                />
              </div>
            </div>
          </div>
          <div className="leftCon">
            <h2>{payDetails.title}</h2>
            <div className="paydetails">
              <div className="pdetailsItem">
                <span>{payDetails.sum}</span>
                <span>{fullTotla.toLocaleString()}</span>
              </div>
              <div className="pdetailsItem">
                <span> {payDetails.dis} </span>
                <span className="discount">
                  {(fullTotla - cart.total).toLocaleString()}
                </span>
              </div>
              <hr className="hr"></hr>
              <div className="pdetailsItem">
                <span className="finalltext">{payDetails.payable}</span>
                <span className="finall">{cart.total.toLocaleString()}</span>
              </div>
            </div>
            {link ? (
              <a href={link} className="toPay">
                {gotopay}
              </a>
            ) : (
              <button className="payBtn" onClick={handlePay} disabled={isFetc}>
                {isFetc ? <CircularIndeterminate /> : pay}{" "}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pay;
