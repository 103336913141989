import React from "react";
import Navbar from "../../componnts/navbar/Navbar";
import Footer from "../../componnts/footer/Footer";
import RecipeOne from "../../componnts/reciepOne/RecipeOne";

const Recipe = () => {
  return (
    <div className="recipe">
      <Navbar />
      <RecipeOne />
      <Footer />
    </div>
  );
};

export default Recipe;
