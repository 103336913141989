import "./locatorLoading.scss";
import { CircularProgress } from "@mui/material";

const LocatorLoading = () => {
  return (
    <div className="locatorLoading">
      <CircularProgress style={{ color: "balck" }} />
    </div>
  );
};

export default LocatorLoading;
