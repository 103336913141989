import { useSelector } from "react-redux";
import useFetch from "../../../functions/useFetch";
import OrderItem from "../orderItem/OrderItem";
import "./changePass.scss";
import { publicRequest } from "../../../functions/request";
import { handleError } from "../../../functions/errorHandling";
import { toast } from "react-toastify";
import { useState } from "react";
import GetText from "../../../functions/getTexts";

const ChangePass = () => {
  const [passInfo, setPassInfo] = useState({
    oldPass: "",
    newPass: "",
    confPass: "",
  });

  const userId = useSelector((state) => state.user.currentUser)._id;

  const handlePass = (e) => {
    setPassInfo((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleSend = async () => {
    if (passInfo.newPass === passInfo.confPass) {
      try {
        const res = await publicRequest.put(
          "/user/changePass/?userId=" + userId,
          passInfo
        );
        toast.success("رمز شما با موفقیت تغییر کرد");
      } catch (error) {
        handleError(error);
      }
    } else {
      toast.error("رمز و. تکرار آن یکسان نیستند");
    }
  };

  const { pass, dir } = GetText("dashboard");

  return (
    <div className={dir ? "changePass" : "changePass-ltr"}>
      <div className="cpContainer">
        <h3>{pass.title}</h3>
        <div className="box">
          <div className="boxItem">
            <span>{pass.previous}:</span>
            <input
              type="text"
              placeholder={pass.previous}
              name="oldPass"
              value={passInfo.oldPass}
              onChange={(e) => handlePass(e)}
            />
          </div>
          <div className="boxItem">
            <span>{pass.next}:</span>
            <input
              type="text"
              placeholder={pass.next}
              name="newPass"
              value={passInfo.newPass}
              onChange={(e) => handlePass(e)}
            />
          </div>
          <div className="boxItem">
            <span>{pass.repeat}:</span>
            <input
              type="text"
              placeholder={pass.repeat}
              name="confPass"
              value={passInfo.confPass}
              onChange={(e) => handlePass(e)}
            />
          </div>
        </div>
        <div className="changeBtn" onClick={handleSend}>
          {pass.change}
        </div>
      </div>
    </div>
  );
};

export default ChangePass;
