import { useSelector } from "react-redux";
import "./ticketItem.scss";
import DoneAllOutlinedIcon from "@mui/icons-material/DoneAllOutlined";
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";
import FolderOutlinedIcon from "@mui/icons-material/FolderOutlined";
import { useEffect, useState } from "react";
import useFetch from "../../../functions/useFetch";
import { handleError } from "../../../functions/errorHandling";
import { publicRequest } from "../../../functions/request";
import { toast } from "react-toastify";
import GetText from "../../../functions/getTexts";
import moment from "jalali-moment";
import { Link } from "react-router-dom";

function TicketItem({ ticket }) {
  const baseUrl = process.env.REACT_APP_IMAGES_SOURCE;
  const user = useSelector((state) => state.user.currentUser);
  const { ticketItem, dir } = GetText("dashboard");

  const { data, error, isFetching } = useFetch("/message/" + ticket._id);

  return (
    !isFetching &&
    !error && (
      <div className={dir ? "ticketItem" : "ticketItem-ltr"}>
        <Link to={"/dashboard/ticket/" + ticket._id} style={{ width: "100%" }}>
          <div className="tiContainer">
            <img src="/images/support.svg" alt="" />
            <div className="details">
              <div className="TicketTop">
                <div className="name">{ticketItem.support}</div>
                <div className="date-delivery">
                  <div className="date">
                    {moment(ticket.createdAt).format("hh:mm")}
                  </div>
                  {data.length > 0 && data[0].delivery ? (
                    <DoneAllOutlinedIcon />
                  ) : (
                    <DoneOutlinedIcon />
                  )}
                </div>
              </div>
              {data.length > 0 ? (
                <div className="bottom">{data[0].text}</div>
              ) : (
                <div className="bottom">بدون پیام</div>
              )}
            </div>
          </div>
        </Link>
      </div>
    )
  );
}

export default TicketItem;
