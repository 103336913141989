import { Icon, divIcon, point } from "leaflet";
import "./locator.scss";
import "leaflet/dist/leaflet.css";
import { markers } from "./data";
import { cheackDir } from "../../functions/cheackDir";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-cluster";
import { useEffect, useState } from "react";
import LocationOn from "@mui/icons-material/LocationOn";
import LocatorLoading from "../locatorLoading/LocatorLoading";
import SupplierDetails from "../supplierDetails/SupplierDetails";
import GetText from "../../functions/getTexts";
import { useTranslation } from "react-i18next";
import useFetch from "../../functions/useFetch";
import { Helmet } from "react-helmet";
const goriupIcon = new divIcon({});

const createCustumIcon = (cluster) => {
  return new divIcon({
    html: `<div class="cluster-icon"> ${cluster.getChildCount()}</div>`,
  });
};

//main Function
const Locator = () => {
  const [centerLocation, setCenterLocation] = useState([35.7219, 51.3347]);
  const [selectedSupplier, setSelectedSupplier] = useState(null);
  const [suppliers, setSuppliers] = useState([]);
  const [cities, setCities] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);

  const newIcon = new Icon({
    iconUrl: "/images/gps.png",
    iconSize: [20, 30],
  });

  const handleGeo = (loc) => {
    setCenterLocation(loc.coordinates);
    setSelectedSupplier(loc);
  };

  const { title, byCity, byCountry, dir } = GetText("locator");
  const { data, error, isFetching } = useFetch("/supplier/find/?isActive=true");

  useEffect(() => {
    let allCountries = data.map((d) => {
      const cities = data
        .filter((s) => s.country == d.country)
        .map((s) => {
          return {
            city: s.city,
            coordinates: s.coordinates,
          };
        });
      return { country: d.country, cities };
    });
    let finall = [];
    allCountries.forEach((c) => {
      const result = finall.find((f) => f.country == c.country);
      if (!result) {
        finall.push(c);
      }
    });
    setSuppliers(finall);
  }, [data]);

  const handleCities = (e) => {
    if (e.target.value != "null") {
      setCities(suppliers.find((s) => s.country == e.target.value).cities);
    } else {
      setCities(null);
    }
  };

  const handleSuppplier = (e) => {
    const sup = data.find((d) => d.city == e.target.value);
    setSelectedSupplier(sup);
    setCenterLocation(sup.coordinates);
  };

  useEffect(() => {
    // setCenterLocation(selectedCity.coordinates);
    selectedSupplier && setCenterLocation(selectedSupplier.coordinates);
  }, [selectedSupplier]);

  return (
    !isFetching &&
    !error && (
      <>
        <div className={dir ? "locator" : "locator-ltr"}>
          <Helmet>
            <title>جستجوی نمایندگی های کندو کندی</title>
            <meta name="description" content="" />
            <meta name="keywords" content="" />
          </Helmet>
          <MapContainer center={centerLocation} zoom={12}>
            <TileLayer
              attribution='&copy; <a href="https://www.stadiamaps.com/" target="_blank">Stadia Maps</a> &copy; <a href="https://openmaptiles.org/" target="_blank">OpenMapTiles</a> &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <MarkerClusterGroup
              chunkedLoading
              iconCreateFunction={createCustumIcon}
              zoomToBoundsOnClick
            >
              {data.map((m, i) => (
                <div key={i} onClick={() => handleGeo(m)}>
                  <Marker
                    position={m.coordinates}
                    autoPanOnFocus={true}
                    icon={newIcon}
                    riseOnHover={true}
                    key={i}
                  >
                    <Popup>
                      <h1 style={{ cursor: "pointer" }}>{m.city}</h1>
                    </Popup>
                  </Marker>
                </div>
              ))}
            </MarkerClusterGroup>
          </MapContainer>
          <div className="theCountries">
            <div className="cContainer">
              {selectedSupplier && (
                <SupplierDetails
                  supplierId={selectedSupplier._id}
                  setSupplier={setSelectedSupplier}
                />
              )}
              {!selectedSupplier && (
                <>
                  <div className="title"> {title} </div>
                  <div className="selectItem">
                    <h3>{byCountry}</h3>
                    <select
                      name=""
                      id=""
                      className="select"
                      onChange={(e) => handleCities(e)}
                    >
                      <option value={"null"}>انتخاب کنید</option>
                      {suppliers?.map((s, i) => (
                        <option value={s.country} key={i}>
                          {s.country}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="selectItem">
                    <h3>{byCity}</h3>
                    <select
                      name=""
                      id=""
                      className="select"
                      onChange={(e) => handleSuppplier(e)}
                    >
                      <option value="">انتخاب نمایید</option>
                      {cities &&
                        cities.map((c, i) => (
                          <option value={c.city} key={i}>
                            {c.city}
                          </option>
                        ))}
                    </select>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </>
    )
  );
};

export default Locator;
