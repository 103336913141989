import "./viewLarge.scss";
import { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const ViewLarge = ({ data, open, setOpen }) => {
  const [selectedImg, setSelectedImg] = useState(0);
  const [activeImg, setActiveImg] = useState(false);
  const handleSelect = (direction) => {
    console.log(direction);
    if (direction == "right") {
      setSelectedImg(
        selectedImg < data.length - 1 ? selectedImg + 1 : selectedImg
      );
    } else {
      setSelectedImg(selectedImg > 0 ? selectedImg - 1 : selectedImg);
    }
  };

  useEffect(() => {
    setActiveImg(open);
  }, [open]);

  const baseUrl = process.env.REACT_APP_IMAGES_SOURCE;
  return (
    <div className="viewLarge" style={{ display: open ? "flex" : "none" }}>
      <CloseIcon
        onClick={() => setOpen(false)}
        className="close"
        fontSize="large"
      />
      <ArrowBackIcon
        className="icon back"
        style={{ display: selectedImg == 0 && "none" }}
        onClick={() => handleSelect("left")}
      />
      <ArrowForwardIcon
        className="icon forward"
        onClick={() => handleSelect("right")}
        style={{ display: selectedImg == data.length - 1 && "none" }}
      />
      <img
        src={baseUrl + data[selectedImg]}
        alt=""
        className={activeImg && "active"}
      />
    </div>
  );
};

export default ViewLarge;
