import "./searchContent.scss";
import RecipesSearchBox from "../recipesSearchBox/RecipesSearchBox";
import SearchIcon from "@mui/icons-material/Search";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useContext, useEffect, useState } from "react";
import RecipesItem from "../reciptItem/RecipesItem";
import { Link, useLocation } from "react-router-dom";
import Loading from "../loading/Loading";
import { Pagination, PaginationItem } from "@mui/material";
import useFetch from "../../functions/useFetch";
import { SearchContext } from "../../context/SearchContext";
import { publicRequest } from "../../functions/request";
import { useTranslation } from "react-i18next";
import SearchBox from "../searchBox/SearchBox";

const SearchContent = () => {
  const [show, setShow] = useState(false);
  const [page, setPage] = useState(1);

  const lan = useTranslation().i18n.language;

  const handleChange = (e, p) => {
    setPage(p);
  };

  const handleCatogery = (id) => {
    setSearchTopic(null);
    setLiked(false);
    setRecent(false);
    setCatogery(id);
    setShow(false);
  };

  const {
    liked,
    setLiked,
    recent,
    setRecent,
    catogeries,
    filter,
    setFilter,
    catogery,
    setCatogery,
    searchTopic,
    setSearchTopic,
  } = useContext(SearchContext);

  const { data, isFetching, error } = useFetch(
    `/recipe/?searched=${searchTopic}&lan=${lan}&recent=${recent}&liked=${liked}&catogery=${catogery}`
  );

  return (
    !isFetching &&
    !error && (
      <div className="searchContent">
        <div className="scContainer">
          <SearchBox />
          <div className="searchResult">
            {searchTopic && (
              <div className="resultTitle">
                نتایج جستجوی شما برای
                <h2>{`"${searchTopic}"`}</h2>
              </div>
            )}
            <div className="resultWraper">
              {data?.slice((page - 1) * 12, page * 12).map((item, i) => {
                return <RecipesItem width={"28%"} key={i} data={item} />;
              })}
            </div>
            <div className="pagination">
              <Pagination
                count={Math.ceil(data.length / 12)}
                renderItem={(item, i) => (
                  <PaginationItem
                    slots={{ previous: ArrowForwardIcon, next: ArrowBackIcon }}
                    {...item}
                    key={i}
                  />
                )}
                onChange={handleChange}
              />
            </div>
          </div>
        </div>
      </div>
      //  <Loading load={load} />
    )
  );
};

export default SearchContent;
