import { json, useNavigate } from "react-router-dom";
import "./cart.scss";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import { useEffect, useRef, useState } from "react";
import CartItem from "../cartItem/CartItem";
import { useTranslation } from "react-i18next";
import GetText from "../../functions/getTexts";
import { useDispatch, useSelector } from "react-redux";
import { ADD_CART, REMOVE_CART, CLEAN_CART } from "../../redux/cartRedux";
import { toast } from "react-toastify";

const Cart = ({ close, show }) => {
  const cartRef = useRef();
  useEffect(() => {
    setTimeout(() => {
      cartRef.current.style.transform = `translateX(0)`;
    }, 100);
  }, []);

  const { i18n } = useTranslation();
  const { title, cleare, complet, sum } = GetText("cart");

  const cart = useSelector((state) => state.cart);
  const currentUser = useSelector((state) => state.user.currentUser);
  const dispatch = useDispatch();

  const handleAdd = () => {
    // dispatch(CLEAN_CAERT());
    dispatch(
      ADD_CART({
        _id: "1",
        count: 10,
        price: 123000,
        disCount: 3,
        number: 1,
      })
    );
  };

  const navigate = useNavigate();
  const gotoPay = () => {
    currentUser
      ? cart.products.length > 0
        ? navigate("/pay")
        : toast.error("سبد شما خالی است")
      : toast.error("نخست وارد حساب کاربری خود شوید");
  };

  return (
    <div className="basket">
      {" "}
      <div className={`cartBox `} ref={cartRef}>
        <div className="cbContainer">
          <div className="basHeader">
            <CloseIcon fontSize="large" onClick={() => close()} />
            <span className="cartName" onClick={handleAdd}>
              {" "}
              {title}
            </span>
            <span className="removeAll" onClick={() => dispatch(CLEAN_CART())}>
              {cleare}{" "}
            </span>
          </div>
          <div className="cartItems">
            {cart.products.length !== 0 ? (
              cart.products?.map((p, i) => {
                return <CartItem data={p} key={i} />;
              })
            ) : (
              <span className="emptyNotif">سبد شما خالی است</span>
            )}
          </div>
          <div className="cartTotal">
            <div className="endShop" onClick={gotoPay}>
              {complet}
            </div>
            <div className="total">
              <span className="totalprice">{sum} </span>
              <span className="sum">{cart.total.toLocaleString()}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cart;
