export const pollinationData = [
  {
    type: "title",
    value: "زنبورهای عسل و گرده افشانی",
  },
  {
    type: "text",
    value:
      "تنوع صنعت زنبورداری در طول زمان تکامل یافته است و گرده افشانی مدیریت شده تنها یکی از راه هایی است که زنبورداران تأثیر زیادی بر جهان می گذارند. گرده افشانی مدیریت شده عمل مراقبت از کندوها و زنبورهای عسل به منظور گرده افشانی محصولاتی است که همه ما را حفظ می کند.",
  },
  {
    type: "subtitle",
    value: "شیفت دوم زنبورهای عسل ",
  },
  {
    type: "text",
    value:
      "زنبورهای عسل علاوه بر جمع‌آوری شهد برای تولید عسل، عملکرد دوم حیاتی را نیز انجام می‌دهند - گرده‌افشانی که آنها را به یکی از اجزای حیاتی بازار کشاورزی امروزی تبدیل می‌کند. در واقع، حدود یک سوم رژیم غذایی انسان از گیاهان گرده افشانی حشرات گرفته می شود و زنبورهای عسل مسئول 80 درصد این گرده افشانی هستند.",
  },
  {
    type: "subtitle",
    value: "گرده افشانی",
  },
  {
    type: "text",
    value:
      " در حالی که زنبورهای عسل در حال جمع آوری شهد هستند، گیاهان گلدار را نیز بارور می کنند. گرده افشانی زمانی اتفاق می افتد که گرده از بساک گل به تخمک گل دیگر منتقل شود. این گرده افشانی کمیت و کیفیت بسیاری از محصولات از جمله انواع میوه ها، سبزیجات، حبوبات و مغزها مانند بادام را به شدت افزایش می دهد. ",
  },
  {
    type: "text",
    value:
      "طبق گفته‌ی Bee Health Collective، در حالی که بسیاری از گرده‌افشان‌ها مشارکت‌کنندگان مهمی هستند، زنبورهای عسل دارای چندین ویژگی هستند که آنها را برای کار گرده افشانی محصولات مناسب می‌سازد: ",
  },
  {
    type: "text",
    value:
      "Tآنها می توانند توسط زنبورداران مدیریت و به تعداد زیاد از محصولی به محصول دیگر منتقل شوندTآنها می توانند توسط زنبورداران مدیریت و به تعداد زیاد از محصولی به محصول دیگر منتقل شوندآنها می توانند توسط زنبورداران مدیریت و به تعداد زیاد از محصولی به محصول دیگر منتقل شوند",
  },
  {
    type: "text",
    value:
      " زنبورهای عسل در تمام 50 ایالت آمریکا گرده افشانی می کنند. برای مشاهده مسیرهای مختلف زنبورهای عسل در طول فصل گرده افشانی، نقشه زیر را بررسی کنید.",
  },
  {
    type: "text",
    value:
      "بسیاری از موادی که به یک رژیم غذایی متعادل و سالم کمک می‌کنند بدون زنبورهای عسل امکان‌پذیر نیستند، و ما نمی‌توانیم بیشتر از این قدردان زحمات زنبورداران و گرده افشان‌های مدیریت شده باشیم. به لطف آنهاست که می توانیم از شیرین کننده مادر طبیعت لذت ببریم و جوامع را با محصولات مغذی مانند میوه ها، سبزیجات و آجیل تغذیه کنیم. ناگفته نماند گوشت و لبنیات، که برای گرده افشانی محصولات خوراک دام، مانند یونجه، به زنبورها وابسته هستند. 90 محصول مختلف وجود دارد که به زنبورداران و زنبورهای عسل متکی هستند، بنابراین این یک عمل ارزشمند است که در نهایت غذا را روی میزهای شام در سراسر کشور قرار می دهد. برای مشاهده برخی از غذاهای گرده افشانی شده توسط زنبورهای عسل، این اینفوگرافیک را بررسی کنید.",
  },
];

export const polonationHeader = {
  type: "img",
  value: "/images/flower3.jpg",
};




