import "./recipesItem.scss";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
import SearchIcon from "@mui/icons-material/Search";
import { useState } from "react";
import { Link } from "react-router-dom";
import GetText from "../../functions/getTexts";
import { useTranslation } from "react-i18next";
import { publicRequest } from "../../functions/request";
import { handleError } from "../../functions/errorHandling";

const RecipesItem = ({ width, data }) => {
  const [liked, setLiked] = useState(false);
  const { majority, dir } = GetText("recipes");

  const lan = useTranslation().i18n.language;
  const baseUrl = process.env.REACT_APP_IMAGES_SOURCE;

  const handleLike = async () => {
    try {
      const res = await publicRequest.put("/recipe/like/" + data._id);
      setLiked(true);
      console.log(res.data);
    } catch (error) {
      console.log(error);
      handleError(error);
    }
  };

  return (
    <div
      className={dir ? "recipesItem" : "recipesItem-ltr"}
      // style={{ width: width }}
    >
      <div className="riContainer">
        <div className="imgContainer">
          <img src={baseUrl + data.img} alt="" />
          <div className="imgHover">
            <div className="icons">
              {!liked ? (
                <FavoriteBorderIcon
                  onClick={handleLike}
                  style={{ color: "white" }}
                  fontSize="large"
                />
              ) : (
                <FavoriteIcon
                  style={{ color: "red" }}
                  onClick={() => setLiked(false)}
                  fontSize="large"
                />
              )}
              <Link to={`/recipe/${data._id}`}>
                <SearchIcon style={{ color: "white" }} fontSize="large" />
              </Link>
            </div>
          </div>
        </div>
        <div className="details" style={{ backgroundColor: "white" }}>
          <div className="title">{data.topic[lan]}</div>
          <Link
            to={"/recipe/sdcsdcsdc"}
            className="seeMore-btn"
            style={{ color: "inherit", textDecoration: "none" }}
          >
            <div className="seeMore-btn">{majority.textbtn}</div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default RecipesItem;
