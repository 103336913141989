import "./login.scss";
import GetText from "../../functions/getTexts";
import CloseIcon from "@mui/icons-material/Close";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { loginCall } from "../../functions/loginCall";
import CircularIndeterminate from "../circleProgress/CircleProgress";
import { useTranslation } from "react-i18next";
import { cheackDir } from "../../functions/cheackDir";

const Login = ({ changePage, close }) => {
  const [showPass, setShowPass] = useState(false);
  const [user, setUser] = useState({
    phone: null,
    password: null,
  });

  const { error, currentUser, isFetching } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleChange = (e) => {
    setUser((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };

  const handleLogin = async () => {
    loginCall(dispatch, user);
  };

  useEffect(() => {
    currentUser && close();
  }, [currentUser]);

  const { title, formDetails } = GetText("login");
  const { i18n } = useTranslation();
  const direction = cheackDir(i18n.language);

  return (
    <div className={` ${direction ? "login" : "login-ltr"}`}>
      <CloseIcon className="closeBtn" onClick={close} />
      <h1 className="title">{title}</h1>
      <div className="loginForm">
        <label htmlFor="">
          {formDetails.phone}
          <input
            type="text"
            placeholder={formDetails.textInput}
            name="phone"
            onChange={(e) => handleChange(e)}
          />
        </label>
        <label htmlFor="">
          {formDetails.pass}
          <input
            type={showPass ? "text" : "password"}
            placeholder="*********"
            name="password"
            onChange={(e) => handleChange(e)}
          />
          {!showPass ? (
            <div className="eye">
              <VisibilityOffIcon onClick={() => setShowPass(true)} />
            </div>
          ) : (
            <div className="eye">
              <VisibilityIcon onClick={() => setShowPass(false)} />
            </div>
          )}
        </label>
        <span className="forgetPass">{formDetails.forgetPass}</span>
        <button className="login" onClick={handleLogin} disabled={isFetching}>
          {isFetching ? <CircularIndeterminate /> : formDetails.textbtn}
        </button>
        <span className="newAcc">
          {formDetails.noAccount}{" "}
          <small onClick={changePage}> {formDetails.registerText}</small>
        </span>
      </div>
    </div>
  );
};

export default Login;
