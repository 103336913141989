import { useTranslation } from "react-i18next";
import GetText from "../../functions/getTexts";
import "./recipeDetails.scss";
import useFetch from "../../functions/useFetch";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";

const RecipeDetails = () => {
  const { details, dir } = GetText("recipe");
  const id = useParams().id;
  const { data, isFetching, error } = useFetch("/recipe/" + id);
  const lan = useTranslation().i18n.language;

  const baseUrl = process.env.REACT_APP_IMAGES_SOURCE;

  return (
    !isFetching &&
    !error && (
      <div className={`recipeDetails ${!dir && "ltr"}`}>
        <Helmet>
          <title>{data.topic[lan]}</title>
          <meta name="description" content="" />
          <meta name="keywords" content="" />
        </Helmet>
        <h1>{data.topic[lan]}</h1>
        <img src={baseUrl + data.img} alt="" />
        <div className="title">{details.title}</div>
        <div className="text">
          {data.matrial[lan].map((d) => {
            return <div className="textItem">{d}</div>;
          })}
        </div>
        <div className="title">{details.dirctions}</div>
        <div className="text">
          {data.prepare[lan].map((p) => (
            <div className="textItem">{p}</div>
          ))}
        </div>
        <div className="title">{details.tip}</div>
        <div className="text">
          {data.note[lan].map((n) => (
            <div className="textItem">{n}</div>
          ))}
        </div>
      </div>
    )
  );
};

export default RecipeDetails;
