import { useTranslation } from "react-i18next";
import GetText from "../../functions/getTexts";
import "./us.scss";
import { cheackDir } from "../../functions/cheackDir";
import useFetch from "../../functions/useFetch";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { builder } from "./db";

const Us = () => {
  const [details, setDetails] = useState({
    phone: "",
    email: {
      support: "",
      info: "",
      sale: "",
    },
    address: {
      fa: "",
      en: "",
      ar: "",
      ru: "",
    },
    description: {
      fa: "",
      en: "",
      ar: "",
      ru: "",
    },
  });
  const { data, error, isFetching } = useFetch("/company");

  useEffect(() => {
    if (data && Object.keys(data).length > 0) {
      const { __v, _id, ...others } = data;
      setDetails(others);
    }
  }, [data]);
  const { title, text, contactDetails } = GetText("aboutus");
  const { i18n } = useTranslation();
  const direction = cheackDir(i18n.language);
  const lan = i18n.language;
  return (
    !isFetching &&
    !error && (
      <div className={direction ? "us" : "us-ltr"}>
        <Helmet>
          <title>درباره ی کندو کندی</title>
          <meta name="description" content="" />
          <meta name="keywords" content="" />
        </Helmet>
        <div className="usContainer">
          <div className="builderMan">
            <div className="builderDetails">
              <h3>{builder[lan].name}</h3>
              <p>{builder[lan].about}</p>
            </div>
            <div className="builderPic">
              <img src="/images/builder.jpg" alt="" />
            </div>
          </div>
          <div className="title"> {title} </div>
          <p className="text">{details.description[lan]}</p>
          <h3 className="subTitle">{contactDetails.title}</h3>
          <div className="contactDetails">
            <div className="cdItem">
              <div className="titleItem">{contactDetails.phone.title} </div>
              <div className="value">{details.phone}</div>
            </div>
            <div className="cdItem">
              <div className="titleItem">{contactDetails.email.title}</div>
              <div className="value">{details.email.info}</div>
            </div>
            <div className="cdItem">
              <div className="titleItem">{contactDetails.email.title}</div>
              <div className="value">{details.email.support}</div>
            </div>
            <div className="cdItem">
              <div className="titleItem">{contactDetails.email.title}</div>
              <div className="value">{details.email.sale}</div>
            </div>
            <div className="cdItem">
              <div className="titleItem">{contactDetails.office.title}</div>
              <div className="value"> {details.address[lan]}</div>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default Us;
