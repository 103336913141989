import { Link } from "react-router-dom";
import { sustabilityNavbar } from "../../data/data";
import "./insideHive.scss";
import { hiveData } from "./insideHiveItems";
import { generateTags } from "../../functions/generateTags";
import MenuList from "../menuList/MenuList";

const NurturingPlanets = () => {
  return (
    <div className="insideHive">
      <div className="bannerContainer">
        <img src="/images/flower2.jpg" alt="" />
      </div>
      <div className="ihContainer">
        <MenuList list={sustabilityNavbar} mwidth={true} />
        <div className="ihContent">
          {hiveData.map((item) => generateTags(item))}
        </div>
      </div>
    </div>
  );
};

export default NurturingPlanets;
