import React from "react";
import Navbar from "../../componnts/navbar/Navbar";
import Pollonation from "../../componnts/pollination/Pollination";
import Footer from "../../componnts/footer/Footer";

const Static = () => {
  return (
    <div className="static">
      <Navbar />
      <Pollonation />
      <Footer />
    </div>
  );
};

export default Static;
