import { useSelector } from "react-redux";
import "./tickets.scss";
import FolderOutlinedIcon from "@mui/icons-material/FolderOutlined";
import { useEffect, useState } from "react";
import useFetch from "../../../functions/useFetch";
import { handleError } from "../../../functions/errorHandling";
import { publicRequest } from "../../../functions/request";
import { toast } from "react-toastify";
import GetText from "../../../functions/getTexts";
import TicketItem from "../ticketItem/TicketItem";

function Tickets() {
  const [imgFile, setImgFile] = useState(null);
  const user = useSelector((state) => state.user.currentUser);

  const { data, error, isFetching, refetch, setRefetch } = useFetch(
    "/ticket/?userId=" + user._id
  );

  const { tickets, dir } = GetText("dashboard");

  const { title } = tickets;

  const updateMessage = async (ticketId) => {
    try {
      await publicRequest.put("/message/unread/" + ticketId);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    !isFetching &&
    !error && (
      <div className="tickets">
        <h1 className={!dir && "ltr"}>{title}</h1>
        <div className="ticketsContent">
          {data.map((t, i) => {
            return (
              <TicketItem
                ticket={t}
                onClick={() => updateMessage(t._id)}
                key={i}
              />
            );
          })}
        </div>
      </div>
    )
  );
}

export default Tickets;
