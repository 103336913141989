import { useTranslation } from "react-i18next";
import "./blogCart.scss";
import { useNavigate } from "react-router-dom";
import GetText from "../../functions/getTexts";

const BlogCart = ({ data }) => {
  const { img, contents, topic, _id } = data;
  const lan = useTranslation().i18n.language;
  const baseUrl = process.env.REACT_APP_IMAGES_SOURCE;
  const navigate = useNavigate();
  const { dir, see } = GetText("slider");

  const handleClick = (id) => {
    navigate("/blogs/" + id);
  };
  return (
    <div className="blogCart">
      <img src={baseUrl + img[lan]} alt="" />
      <div className="details">
        <p style={{ textAlign: dir ? "right" : "left" }}>{topic[lan]}</p>
        <div
          className="seemore"
          onClick={() => handleClick(_id)}
          style={{ marginRight: !dir && "10px", marginLeft: !dir && "auto" }}
        >
          {see}
        </div>
      </div>
    </div>
  );
};

export default BlogCart;
