import AboutBeekiping from "../../componnts/aboutBeekiping/AboutBeekiping";
import AboutHoney from "../../componnts/aboutHoney/AboutHoney";
import Announcment from "../../componnts/annoncment/Announcment";
import Banner from "../../componnts/banner/Banner";
import { Bees } from "../../componnts/bees/Bees";
import Blog from "../../componnts/blog/Blog";
import Footer from "../../componnts/footer/Footer";
import Navbar from "../../componnts/navbar/Navbar";
import Slider from "../../componnts/slider/Slider";
import Loading from "../../componnts/loading/Loading";
import "./home.scss";
import { Helmet } from "react-helmet";

const Home = () => {
  const isloading = true;
  return (
    <div className="home">
      <Helmet>
        <title>صفحه اصلی</title>
        <meta name="description" content="صفحه اصلی سایت کندو کندی" />
        <meta name="kewords" content="عسل , پنیر , " />
      </Helmet>
      <Navbar />
      <Banner />
      <AboutBeekiping />
      <Bees />
      <AboutHoney />
      <Slider />
      <Blog />
      <Footer />
      <Announcment />
    </div>
  );
};

export default Home;
