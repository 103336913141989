import { useTranslation } from "react-i18next";
import GetText from "../../functions/getTexts";
import "./supplierDetails.scss";
import { cheackDir } from "../../functions/cheackDir";
import { useState } from "react";
import useFetch from "../../functions/useFetch";
import LocatorLoading from "../locatorLoading/LocatorLoading";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import EastOutlinedIcon from "@mui/icons-material/EastOutlined";
const SupplierDetails = ({ supplierId, setSupplier }) => {
  const { data, isFetching, error } = useFetch("/supplier/find/" + supplierId);

  const { dir, contact, supplierName, honeyPrducts, sendWays, orderSize } =
    GetText("supplierDetails");

  return !isFetching ? (
    <div className={dir ? "supplierDetails" : "supplierDetails-ltr"}>
      {dir ? (
        <KeyboardBackspaceIcon
          className="backicon"
          onClick={() => setSupplier(null)}
          fontSize="large"
        />
      ) : (
        <EastOutlinedIcon
          className="backicon"
          onClick={() => setSupplier(null)}
          fontSize="large"
        />
      )}
      <h3>{data.city}</h3>

      <div className="item">
        <h4> {contact}</h4>
        <span>{data.contact.email}</span>
        <span>{data.contact.phone}</span>
        <span>{data.address}</span>
      </div>
      <div className="item">
        <h4>{supplierName} </h4>
        <span>{data.manager}</span>
      </div>
      <div className="item">
        <h4> {honeyPrducts}</h4>
        {data.products.map((p, i) => {
          return <span key={i}>{p}</span>;
        })}
      </div>
      <div className="item">
        <h4> {sendWays}</h4>
        <span>{data.sendWay}</span>
      </div>
      <div className="item">
        <h4>{orderSize}</h4>
        <span>{data.orderSize}</span>
      </div>
    </div>
  ) : (
    <LocatorLoading />
  );
};

export default SupplierDetails;
