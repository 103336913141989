const falist = [
  {
    title: "اخبار",
    link: "/newsroom",
  },
  {
    title: "ویدیو ها",
    link: "/newsroom/videos",
  },
  {
    title: "تصاویر",
    link: "/newsroom/photoes",
  },
];


const enlist = [
  {
    title: "news room",
    link: "/newsroom",
  },
  {
    title: "news room",
    link: "/newsroom",
  },
  {
    title: "news room",
    link: "/newsroom",
  },
];

module.exports = {
  falist,
  enlist,
};
