import "./products.scss";
import Navbar from "../../componnts/navbar/Navbar";
import Footer from "../../componnts/footer/Footer";
import { useLocation } from "react-router-dom";
import ProductsContent from "../../componnts/productsContent/ProductsContent";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";

const Products = () => {
  return (
    <div className="products">
      <Helmet>
        <title>محصولات</title>
        <meta name="description" content="" />
        <meta name="keywords" content="" />
      </Helmet>
      <Navbar />
      <ProductsContent />
      <Footer />
    </div>
  );
};

export default Products;
