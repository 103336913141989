import "./rightSide.scss";
import { LOG_OUT } from "../../redux/userRedux";
import GridViewIcon from "@mui/icons-material/GridView";
import ExitToAppOutlinedIcon from "@mui/icons-material/ExitToAppOutlined";
import CreditScoreOutlinedIcon from "@mui/icons-material/CreditScoreOutlined";
import LocalMallOutlinedIcon from "@mui/icons-material/LocalMallOutlined";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import KeyboardArrowUpOutlinedIcon from "@mui/icons-material/KeyboardArrowUpOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import { useState } from "react";
import VpnKeyOutlinedIcon from "@mui/icons-material/VpnKeyOutlined";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { use } from "i18next";
import GetText from "../../functions/getTexts";
import useFetch from "../../functions/useFetch";

const RightSide = () => {
  const [showprofile, setShowwProfile] = useState(false);
  const [selected, setSElected] = useState();
  const location = useLocation().pathname;
  let activeItem = location.split("/");

  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.currentUser);

  const handleExite = () => {
    dispatch(LOG_OUT());
  };

  const { data, isFetching, error } = useFetch("/user/find?userId=" + user._id);

  const { right, dir } = GetText("dashboard");
  const baseUrl = process.env.REACT_APP_IMAGES_SOURCE;

  return (
    <div className="rightSide">
      <div className="rsContainer">
        {!isFetching && !error && (
          <div className="profile">
            <div className="top">
              <img
                src={data.img ? baseUrl + data.img : "/images/noimage.jpg"}
                alt=""
              />
              <div className="details">
                <div className="numBox">
                  {showprofile ? (
                    <KeyboardArrowUpOutlinedIcon
                      className="icon"
                      onClick={() => setShowwProfile(false)}
                    />
                  ) : (
                    <KeyboardArrowDownOutlinedIcon
                      className="icon"
                      onClick={() => setShowwProfile(true)}
                    />
                  )}
                  <span>{data.phone}</span>
                </div>
                {showprofile && (
                  <div className="userDetails">
                    <div
                      className={`udItem`}
                      style={{ direction: dir ? "rtl" : "ltr" }}
                    >
                      <span>{right.name}</span>
                      <span>{data.username ? data.username : "نامشخص"}</span>
                    </div>
                    <div
                      className="udItem"
                      style={{ direction: dir ? "rtl" : "ltr" }}
                    >
                      <span>{right.amount}</span>
                      <span>{data.amount.toLocaleString()}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
        <ul className={!dir && "ltr"}>
          <Link to={"/dashboard"}>
            <li>
              <GridViewIcon />
              <span>{right.profile}</span>
            </li>{" "}
          </Link>
          <Link to={"/dashboard/orders"}>
            <li>
              <LocalMallOutlinedIcon />
              <span>{right.orders}</span>
            </li>{" "}
          </Link>
          <Link to={"/dashboard/tickets"}>
            <li>
              <EmailOutlinedIcon />
              <span>{right.tickets}</span>
            </li>{" "}
          </Link>
          <Link to={"/dashboard/vallet"}>
            <li>
              <CreditScoreOutlinedIcon />
              <span> {right.vallet}</span>
            </li>{" "}
          </Link>
          <Link to={"/dashboard/changePass"}>
            <li>
              <VpnKeyOutlinedIcon />
              <span>{right.password}</span>
            </li>
          </Link>
          <li onClick={handleExite}>
            <ExitToAppOutlinedIcon />
            <span>{right.exite}</span>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default RightSide;
