import { useState } from "react";
import "./register.scss";
import CloseIcon from "@mui/icons-material/Close";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { publicRequest } from "../../functions/request";
import axios from "axios";
import { toast } from "react-toastify";
import { handleError } from "../../functions/errorHandling";
import GetText from "../../functions/getTexts";
import { useTranslation } from "react-i18next";
import { cheackDir } from "../../functions/cheackDir";

const Register = ({ changePage, close }) => {
  const [showPass, setShowPass] = useState(false);
  const [showPassConf, setShowPassConf] = useState(false);
  const [userDetails, setUserDetails] = useState({
    phone: null,
    password: null,
    confPassword: null,
  });

  const handleRegister = () => {
    const regis = async () => {
      try {
        const res = await publicRequest.post("/auth/register", userDetails);
        toast.success("حساب شما با موفقیت ایجاد گردید");
      } catch (error) {
        console.log(error);
        handleError(error);
        // if (error.status == 500) {
        //   toast.error("اطلاعات خواسته شده را به درستی وارد نمایید");
        // }
        // const err = error.response.data;
      }
    };

    //credential, if pass and conf was equal.
    userDetails.password == userDetails.confPassword
      ? regis()
      : toast.error("رمز و تکرار آن یکسان نیستند");
  };

  const handleChange = (e) => {
    setUserDetails((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };

  const { title, formDetails } = GetText("register");
  const { i18n } = useTranslation();
  const direction = cheackDir(i18n.language);

  return (
    <div className={direction ? "register" : "register-ltr"}>
      <CloseIcon className="closeBtn" onClick={close} />
      <h1 className="title">{title}</h1>
      <div className="loginForm">
        <label htmlFor="">
          {formDetails.phone}
          <input
            type="text"
            placeholder={formDetails.textInput}
            name="phone"
            onChange={(e) => handleChange(e)}
          />
        </label>
        <label htmlFor="">
          {formDetails.pass}
          <input
            type={showPass ? "text" : "password"}
            placeholder="*********"
            name="password"
            onChange={(e) => handleChange(e)}
          />
          {!showPass ? (
            <VisibilityOffIcon
              onClick={() => setShowPass(true)}
              className="eye"
            />
          ) : (
            <VisibilityIcon
              onClick={() => setShowPass(false)}
              className="eye"
            />
          )}
        </label>
        <label htmlFor="">
          {formDetails.confPass}
          <input
            type={showPassConf ? "text" : "password"}
            placeholder="*********"
            name="confPassword"
            onChange={(e) => handleChange(e)}
          />
          {!showPassConf ? (
            <VisibilityOffIcon
              onClick={() => setShowPassConf(true)}
              className="eye"
            />
          ) : (
            <VisibilityIcon
              onClick={() => setShowPassConf(false)}
              className="eye"
            />
          )}
        </label>
        <button className="login" onClick={handleRegister}>
          {formDetails.textbtn}
        </button>
        <span className="newAcc">
          {formDetails.hasAccount}
          <small onClick={changePage}>{formDetails.loginText}</small>
        </span>
      </div>
    </div>
  );
};

export default Register;
