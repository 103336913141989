import "./aboutUs.scss";
import Navbar from "../../componnts/navbar/Navbar";
import Footer from "../../componnts/footer/Footer";
import Us from "../../componnts/us/Us";

const AboutUs = () => {
  return (
    <div className="aboutUs">
      <Navbar />
      <Us />
      <Footer />
    </div>
  );
};

export default AboutUs;
