import { useSelector } from "react-redux";
import "./profile.scss";
import FolderOutlinedIcon from "@mui/icons-material/FolderOutlined";
import { useEffect, useState } from "react";
import useFetch from "../../../functions/useFetch";
import { handleError } from "../../../functions/errorHandling";
import { publicRequest } from "../../../functions/request";
import { toast } from "react-toastify";
import GetText from "../../../functions/getTexts";

function Profile() {
  const [imgFile, setImgFile] = useState(null);
  const user = useSelector((state) => state.user.currentUser);
  const { data, error, isFetching, refetch, setRefetch } = useFetch(
    "/user/find/?userId=" + user._id
  );

  const [info, setInfo] = useState({});
  useEffect(() => {
    setInfo({
      username: data.username,
      country: data.country,
      phone: data.phone,
      city: data.city,
      address: data.address,
    });
  }, [data]);

  const handleChange = (e) => {
    setInfo((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const upload = async () => {
    const formData = new FormData();
    formData.append("image", imgFile);
    try {
      const res = await publicRequest.post("/upload/img", formData);
      return res.data;
    } catch (error) {
      console.log(error);
    }
  };

  const handleEdite = async (e) => {
    let newImg;
    let newData;
    if (imgFile) {
      newImg = await upload();
      newData = {
        ...info,
        img: newImg,
      };
    } else {
      newData = {
        ...info,
        img: user.img,
      };
    }
    try {
      const res = await publicRequest.put("/user/?userId=" + user._id, newData);
      setRefetch(!refetch);
      toast.success("اطلاعات شما با موفقیت به روزرسانی شد");
    } catch (error) {
      handleError(error);
    }
  };
  const baseUrl = process.env.REACT_APP_IMAGES_SOURCE;

  const { profile, dir } = GetText("dashboard");
  const { formItem, titr } = profile;

  return (
    !isFetching &&
    !error && (
      <div className={dir ? "prof" : "prof-ltr"}>
        <div className="profContainer">
          <h3 className="h3">{profile.title}</h3>
          <div className="profContent">
            <div className="top">
              <img
                src={
                  imgFile
                    ? URL.createObjectURL(imgFile)
                    : data.img
                    ? baseUrl + data.img
                    : "/images/noimage.jpg"
                }
                alt=""
              />
              <div className="selectImg">
                <label htmlFor="image">
                  <span>{profile.selectP}</span>
                  <FolderOutlinedIcon />
                  <input
                    type="file"
                    id="image"
                    style={{ display: "none" }}
                    onChange={(e) => setImgFile(e.target.files[0])}
                  />
                </label>
              </div>
            </div>
            <div className="bottom">
              <h4>{titr}</h4>
              <div className="details">
                <div className="detItem">
                  <span>{formItem.name.title}</span>
                  <input
                    type="text"
                    placeholder={formItem.name.place}
                    name="username"
                    value={info.username}
                    onChange={(e) => handleChange(e)}
                  />
                </div>
                <div className="detItem">
                  <span>{formItem.phone.title}</span>
                  <input
                    type="text"
                    placeholder={formItem.phone.place}
                    name="phone"
                    value={user.phone}
                  />
                </div>
                <div className="detItem">
                  <span>{formItem.country.title}</span>
                  <input
                    type="text"
                    placeholder={formItem.country.place}
                    name="country"
                    value={info.country}
                    onChange={(e) => handleChange(e)}
                  />
                </div>
                <div className="detItem">
                  <span>{formItem.city.title}</span>
                  <input
                    type="text"
                    placeholder={formItem.city.place}
                    name="city"
                    value={info.city}
                    onChange={(e) => handleChange(e)}
                  />
                </div>
                <div className="detItem">
                  <span>{formItem.address.title}</span>
                  <input
                    type="text"
                    placeholder={formItem.address.place}
                    name="address"
                    value={info.address}
                    onChange={(e) => handleChange(e)}
                  />
                </div>
              </div>
              <div className="editBtn" onClick={handleEdite}>
                {formItem.edite}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
}

export default Profile;
