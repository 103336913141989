import { useSelector } from "react-redux";
import useFetch from "../../../functions/useFetch";
import OrderItem from "../orderItem/OrderItem";
import "./orders.scss";
import GetText from "../../../functions/getTexts";

const Orders = () => {
  const userId = useSelector((state) => state.user.currentUser)._id;
  const { data, error, isFetching } = useFetch("/order/find/?userId=" + userId);

  const { orders, dir } = GetText("dashboard");
  return (
    <div className="orders">
      <div className="ordersContainer">
        <h3> {orders.title}</h3>
        <div className="orderList">
          {!isFetching && !error && data.length > 0
            ? data.map((o, i) => {
                return <OrderItem data={o} key={i} />;
              })
            : "تا کنون سفارشی نداشته اید"}
        </div>
      </div>
    </div>
  );
};

export default Orders;
