import "./pannelItems.scss";
import { LOG_OUT } from "../../redux/userRedux";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";

const PannelItems = ({ open }) => {
  const dispatch = useDispatch();

  return (
    <div className="pannelItems" style={{ display: open ? "flex" : "none" }}>
      <Link to={"/dashboard"}>
        <div className="pItems">داشبورد</div>
      </Link>
      <Link to={"/dashboard"}>
        <div className="pItems">تغییر رمز</div>
      </Link>
      <div className="pItems" onClick={() => dispatch(LOG_OUT())}>
        خروج
      </div>
    </div>
  );
};

export default PannelItems;
