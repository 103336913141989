import "./imgBanner.scss";

const ImgBanner = ({ src }) => {
  return (
    <div className="imgBanner">
      <img src={src} alt="" />
    </div>
  );
};

export default ImgBanner;
