import { useTranslation } from "react-i18next";
import "./bees.scss";
import EastIcon from "@mui/icons-material/East";
import { useEffect, useRef } from "react";
import useFetch from "../../functions/useFetch";
import { Link } from "react-router-dom";

export const Bees = () => {
  const { t, i18n } = useTranslation();
  const { line1, line2, showBees } = t("bees");

  const beesRef = useRef();

  const { data, isFetching, error } = useFetch(
    "/image/find/?page=main&place=bees&isSelect=true"
  );
  const baseUrl = process.env.REACT_APP_IMAGES_SOURCE;
  useEffect(() => {
    if (data.length > 0) {
      console.log(data[0].link, "this");
      beesRef.current.style.background = `url(${baseUrl + data[0].link})`;
      beesRef.current.style.backgroundRepeat = "not-repeat";
    }
  }, [data]);

  return (
    !isFetching &&
    !error && (
      <div className="bees" ref={beesRef}>
        <div className="textContainer">
          <h2 className="text">{line1} </h2>
          <h1>{line2}</h1>
          <div className="btn">
            <Link to={"/newsroom/photoes"}>
              <span>{showBees}</span>
            </Link>
          </div>
        </div>
      </div>
    )
  );
};
