import * as React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

export default function CircularIndeterminate() {
  return (
    <Box sx={{ display: "flex" }}>
      <CircularProgress
        style={{
          color: "rgba(0,0,0,.5)",
          padding: "0",
          margin: "0",
        }}
      />
    </Box>
  );
}
