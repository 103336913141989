import "./generateSlider.scss";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import { useEffect, useState } from "react";
import VideoPlayer from "../videoPlyer/VideoPlayer";

const GenerateSlider = ({ width }) => {
  const [open, setOpen] = useState(false);
  const [sliderPrv, setSliderPrv] = useState(4);

  const handleClickOpen = () => {
    setOpen(true);
    console.log("open");
  };
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const resize = () => {
      if (window.innerWidth > 1024) {
        setSliderPrv(4);
      } else if (window.innerWidth > 768) {
        setSliderPrv(3);
      } else if (window.innerWidth > 450) {
        setSliderPrv(2);
      } else {
        setSliderPrv(1);
      }
    };
    window.addEventListener("resize", resize);
    return () => {
      window.removeEventListener("resize", resize);
    };
  }, []);

  return (
    <div className="generateSlider" style={{ width: width || "100%" }}>
      <Swiper
        // install Swiper modules
        modules={[Navigation, Pagination, Scrollbar, A11y]}
        spaceBetween={30}
        slidesPerView={sliderPrv}
        navigation
        //   pagination={{ clickable: true }}
        //   scrollbar={{ draggable: true }}
        onSwiper={(swiper) => console.log(swiper)}
        onSlideChange={() => console.log("slide change")}
      >
        <>
          {Array(15)
            .fill(0)
            .map((item, i) => {
              return (
                <SwiperSlide key={i}>
                  <div className="sItem" onClick={handleClickOpen}>
                    <div className="imgContainer">
                      <img src="/images/slider2.jpg" alt="" />
                    </div>
                    <div className="details">
                      <span>ویدیوی بارگذاری شده از زنبورهای مای ما</span>
                      <div>
                        <PlayCircleOutlineIcon className="play" />
                      </div>
                    </div>
                    <div className="cover"></div>
                  </div>
                </SwiperSlide>
              );
            })}
        </>
      </Swiper>
      <VideoPlayer openVideo={open} open={setOpen} />
    </div>
  );
};

export default GenerateSlider;
