import { Slider } from "@mui/material";

export const generateTags = (property, lan) => {
  return (
    <>
      <h1 className="subtitle">{property.title[lan]}</h1>
      <p className="text">{property.text[lan]}</p>
    </>
  );
};

export const generateHeader = (header) => {
  switch (header.type) {
    case "img":
      return <img src={header.value} />;
    case "slider":
      return <Slider data={header.value} />;
    default:
      break;
  }
};
