import "./recipeOne.scss";
import SearchIcon from "@mui/icons-material/Search";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useContext, useState, useTransition } from "react";
import RecipeDetails from "../recipeDetails/RecipeDetails";
import GetText from "../../functions/getTexts";
import useFetch from "../../functions/useFetch";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { SearchContext } from "../../context/SearchContext";
import SearchBox from "../searchBox/SearchBox";

const RecipeOne = () => {
  const { searchTopic, filter, catogery, catogeries } =
    useContext(SearchContext);

  const [searching, setSearching] = useState(searchTopic);
  const [show, setShow] = useState(false);

  const { search, dir } = GetText("recipe");
  const lan = useTranslation().i18n.language;

  return (
    <div className={dir ? "recipeOne" : "recipeOne-ltr"}>
      <div className="roContainer">
        <SearchBox />
        <RecipeDetails />
      </div>
    </div>
  );
};

export default RecipeOne;
