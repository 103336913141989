export const languages = [
  {
    language: "فارسی",
    flag: "/images/iran.png",
    code: "fa",
  },
  {
    language: "English",
    flag: "/images/enflag.png",
    code: "en",
  },
  {
    language: "العربیه",
    flag: "/images/arabic.png",
    code: "ar",
  },
  {
    language: "Русский",
    flag: "/images/russia.png",
    code: "ru",
  },
];
