import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "../src/redux/store";
import { Provider } from "react-redux";
import { I18nextProvider, initReactI18next } from "react-i18next";
import i18next from "i18next";
import I18nextBrowserLanguageDetector from "i18next-browser-languagedetector/cjs";
import Backend from "i18next-http-backend";

const lan = localStorage.getItem("i18nextLng");

i18next
  .use(Backend)
  .use(I18nextBrowserLanguageDetector)
  .use(initReactI18next)
  .init({
    interpolation: { escapeValue: false },
    debug: true,
    returnObjects: true,
    lng: lan ? lan : "fa",
    backend: {
      // loadPath: `/locales/${lan}/translation.json`,
    },
  });

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18next}>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <React.Suspense>
            <App />
          </React.Suspense>
        </PersistGate>
      </Provider>
      <ToastContainer />
    </I18nextProvider>
  </React.StrictMode>
);
