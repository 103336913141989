import "./videoPlayer.scss";
// import React from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import React, { useEffect, useRef, useState } from "react";
import { useTheme } from "@mui/material/styles";
import { DialogContentText } from "@mui/material";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const VideoPlayer = ({ openVideo, open }) => {
  const [activeVideo, setActiveVideo] = useState(false);
  const theme = useTheme();

  const handleClose = () => {
    setActiveVideo(false);
    setTimeout(() => {
      open(false);
    }, 800);
  };

  useEffect(() => {
    if (openVideo) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "scroll";
    }
  }, [openVideo]);

  useEffect(() => {
    setActiveVideo(openVideo);
  }, [openVideo]);

  const playerRef = useRef();

  const clickOutSide = (e) => {
    if (openVideo && playerRef.current?.contains(e.target)) {
      handleClose()
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", clickOutSide);
    return () => document.removeEventListener("mousedown", clickOutSide);
  }, [openVideo]);

  return (
    <>
      {openVideo && (
        <div className={"videoPlayer"} ref={playerRef}>
          <span onClick={handleClose} className="close">
            Close
          </span>
          <div className={`vpContainer ${activeVideo && "active"}`}>
            <video src="/videos/honey.mp4" controls></video>
          </div>
        </div>
      )}
    </>
  );
};

export default VideoPlayer;
