import "./navbar.scss";
import SearchIcon from "@mui/icons-material/Search";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
// import { navbarList } from "../../data/data";
import React, { useEffect, useState, useTransition } from "react";
import { Link, json } from "react-router-dom";
import LoginModal from "../loginModal/LoginModal";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Cart from "../cart/Cart";
import { useSelector } from "react-redux";
import PannelItems from "../pannelItems/PannelItems";
import { useTranslation } from "react-i18next";
import ChangeLan from "../changeLan/ChangeLan";
import Language from "@mui/icons-material/Language";
import NavMenu from "../navMenu/NavMenu";
import { publicRequest } from "../../functions/request";
import Search from "../search/Search";
import ClearIcon from "@mui/icons-material/Clear";
import useFetch from "../../functions/useFetch";
import UndoIcon from "@mui/icons-material/Undo";
import GetText from "../../functions/getTexts";

const Navbar = () => {
  const [hidde, setHidde] = useState(false);
  const [pname, setPname] = useState("all");
  const [scrolled, setScrolled] = useState(false);
  const [index, setIndex] = useState(0);
  const [searched, setSearched] = useState("");
  const [open, setOpen] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [img, setImg] = useState(null);
  const [showCart, setShowCart] = useState(false);
  const [openPannel, setOpenPannel] = useState(false);
  const { t, i18n } = useTranslation();

  const { navbarList, navbarbtn, inputplace, dir } = GetText("navbar");
  const currentUser = useSelector((state) => state.user.currentUser);

  useEffect(() => {
    const getLogo = async () => {
      try {
        const res = await publicRequest.get(
          "/image/find/?place=logo&isSelect=true"
        );
        setImg(res.data[0]);
      } catch (error) {
        console.log(error);
      }
    };
    getLogo();
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  window.onscroll = () => {
    if (window.pageYOffset > 150) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
    return () => {};
  };

  const baseurl = process.env.REACT_APP_IMAGES_SOURCE;
  const handleChange = (e) => {
    setSearched(e.target.value);
  };

  return (
    <>
      <div
        className={`${!dir ? "navbar-ltr" : "navbar"} ${
          scrolled && "onScroll"
        }`}
      >
        <div className="navbarContainer">
          {!hidde && (
            <Link to={"/"}>
              <div className="logo">
                <img
                  src={
                    img
                      ? process.env.REACT_APP_IMAGES_SOURCE + img.link
                      : "/images/logo2.png"
                  }
                  alt=""
                />
              </div>
            </Link>
          )}
          <div className={`searchBoxNav ${hidde && "ac"}`}>
            <input
              type="text"
              className={hidde && "ac"}
              name="searched"
              value={searched}
              placeholder={inputplace}
              onChange={(e) => handleChange(e)}
            />
            {searched ? (
              <ClearIcon
                // className="removeinput"
                className="icon-search"
                onClick={() => setSearched("")}
              />
            ) : !hidde ? (
              <SearchIcon
                className="icon-search"
                onClick={() => setHidde(true)}
              />
            ) : (
              <UndoIcon
                className="icon-search"
                onClick={() => setHidde(false)}
              />
            )}
          </div>
          {!hidde && <ChangeLan />}
          {!hidde && (
            <div className="buttons">
              {currentUser ? (
                <div
                  className="userProfile"
                  onMouseEnter={() => setOpenPannel(true)}
                  onMouseLeave={() => setOpenPannel(false)}
                >
                  <span>{currentUser.phone}</span>
                  <KeyboardArrowDownIcon className="showuserDetails" />
                  <img
                    src={
                      currentUser.img
                        ? baseurl + currentUser.img
                        : "/images/noimage.jpg"
                    }
                    alt=""
                  />
                  <PannelItems open={openPannel} />
                </div>
              ) : (
                <>
                  <div className="login-btn" onClick={handleClickOpen}>
                    {navbarbtn}
                  </div>
                </>
              )}
            </div>
          )}
        </div>
        <NavMenu />
        {searched && <Search searched={searched} setSearched={setSearched} />}
      </div>
      <LoginModal
        closeModal={handleClose}
        openModal={handleClickOpen}
        open={open}
      />
    </>
  );
};

export default Navbar;
