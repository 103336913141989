import { Outlet } from "react-router-dom";
import "./dashboard.scss";
import RightSide from "../componnts/rightSide/RightSide";
import Navbar from "../componnts/navbar/Navbar";
import Footer from "../componnts/footer/Footer";
import GetText from "../functions/getTexts";

const DashLayout = () => {
  const { dir } = GetText("dashboard");

  return (
    <div>
      <Navbar />
      <div className={dir ? "dashboard" : "dashboard-ltr"}>
        <div className="dashContainer">
          <RightSide />
          <div style={{ flex: 3 }}>
            <Outlet />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default DashLayout;
