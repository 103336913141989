import "./recipesSearchBox.scss";
import SearchIcon from "@mui/icons-material/Search";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import GetText from "../../functions/getTexts";
import useFetch from "../../functions/useFetch";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useContext } from "react";
import { SearchContext } from "../../context/SearchContext";

const RecipesSearchBox = ({ flex }) => {
  const [show, setShow] = useState(false);
  const navigate = useNavigate();

  const {
    catogeries,
    filter,
    setFilter,
    catogery,
    setCatogery,
    searchTopic,
    setSearchTopic,
  } = useContext(SearchContext);

  const handleSearchFilter = (filter) => {
    navigate("/recipes/search", {
      state: { filter },
    });
  };

  const handleSelectCatogery = (id) => {
    setCatogery(id);
    setShow(false);
    navigate("/recipes/search");
  };

  const { search, dir } = GetText("recipes");
  const lan = useTranslation().i18n.language;

  return (
    <div className="rContent">
      <div className={`rcContainer ${flex && "rowMode"}`}>
        <div className="title">{search.title}</div>
        <div className="search">
          <Link to={"/recipes/search"}>
            <SearchIcon className="icon" fontSize="large" />
          </Link>
          <input
            type="text"
            style={{ direction: dir ? "rtl" : "ltr" }}
            placeholder={search.placeholder}
            onChange={(e) => setSearchTopic(e.target.value)}
          />
        </div>
        <div className="filters">
          <div className="filter">
            <ArrowDropDownIcon
              className="icon"
              onClick={() => setShow(!show)}
            />
            <span className="titr"> {search.type}</span>
            <div className={`meals `}>
              <div className={`mealContainer ${show && "active"}`}>
                {catogeries.map((d, i) => (
                  <div
                    className="meal"
                    onClick={() => handleSelectCatogery(d._id)}
                    key={i}
                  >
                    {d.title[lan]}
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="filter">
            <span className="titr" onClick={() => handleSearchFilter("recent")}>
              {search.recent}
            </span>
          </div>
          <div className="filter" style={{ borderLeft: "none" }}>
            <span className="titr" onClick={() => handleSearchFilter("most")}>
              {search.most}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecipesSearchBox;
