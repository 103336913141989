import Footer from "../../componnts/footer/Footer";
import Navbar from "../../componnts/navbar/Navbar";
import SearchContent from "../../componnts/searchContent/SearchContent";
import "./search.scss";

const Search = () => {
  return (
    <div>
      <Navbar />
      <SearchContent />
      <Footer />
    </div>
  );
};

export default Search;
