import "./navMenu.scss";
import Cart from "../cart/Cart";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import MenuIcon from "@mui/icons-material/Menu";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { useState } from "react";
import { Link } from "react-router-dom";
import { t } from "i18next";
import GetText from "../../functions/getTexts";
import { MobileNav } from "../mobileNavbar/MobileNav";
import { useSelector } from "react-redux";

const imgArray = [
  "/images/slider3.png",
  "/images/slider2.jpg",
  "/images/chees.png",
];
const NavMenu = () => {

  const [imgIndex, setImgindex] = useState(0);
  const [showCart, setShowCart] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const { navbarList, navbarbtn, inputplace, dir } = GetText("navbar");

  const cart = useSelector((state) => state.cart);

  return (
    <div className={dir ? "menu" : "menu-ltr"}>
      <div className="menuContainer">
        <div className="cart">
          <ShoppingCartIcon
            className="cart-icon"
            fontSize="large"
            onClick={() => setShowCart(true)}
          />
          {cart.products.length > 0 && (
            <span className="cartCounter">{cart.products.length}</span>
          )}
        </div>
        <MenuIcon className="moreMenu" onClick={() => setShowMenu(!showMenu)} />
        {showMenu && <MobileNav navbarList={navbarList} showMenu={showMenu} />}
        <div className="items">
          {navbarList.map((nitem, i) => {
            return (
              <Link
                style={{ color: "inherit", textDecoration: "none" }}
                to={`${nitem.link}`}
                key={i}
                state={"همه محصولات"}
              >
                <div
                  className="menuItem"
                  onMouseEnter={() => {
                    setShowMore(nitem.title == "products||محصولات" && true);
                  }}
                  onMouseLeave={() => {
                    setShowMore(nitem.title == "products||محصولات" && false);
                  }}
                >
                  {nitem.title}
                  {nitem.title == "products||محصولات" && (
                    <KeyboardArrowDownIcon />
                  )}
                  {/* {nitem.title == "products||محصولات" && (
       
                  )} */}
                </div>
              </Link>
            );
          })}
        </div>
      </div>
      {showCart && <Cart close={setShowCart} show={showCart} />}
    </div>
  );
};

export default NavMenu;

// More Products
{
  /* <div
className="morePro"
style={{ display: showMore ? "flex" : "none" }}
>
<div className="mpContainer">
  <div className="right">
    <Link
      to={"/products"}
      state={"عسل"}
      style={{
        color: "inherit",
        textDecoration: "none",
      }}
    >
      <div
        className="mpItem"
        onMouseEnter={() => setImgindex(0)}
      >
        عسل
      </div>
    </Link>
    <Link
      to={"/products"}
      state={"لواشک"}
      style={{
        color: "inherit",
        textDecoration: "none",
      }}
    >
      <div
        className="mpItem"
        onMouseEnter={() => setImgindex(1)}
      >
        لواشک
      </div>
    </Link>
    <Link
      to={"/products"}
      state={"پنیر"}
      style={{
        color: "inherit",
        textDecoration: "none",
      }}
    >
      <div
        className="mpItem"
        onMouseEnter={() => setImgindex(2)}
      >
        پنیر
      </div>
    </Link>
  </div>
  <div className="left">
    <img src={imgArray[imgIndex]} alt="" />
  </div>
</div>
</div> */
}
