import { useRef, useState } from "react";
import Comments from "../comments/Comments.jsx";
import ExampleSlider from "../exampleSlider/ExampleSlider.jsx";
import PIntroduction from "../pIntroduction/PIntroduction.jsx";
import "./productContent.scss";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import GetText from "../../functions/getTexts.js";
import { useSelector } from "react-redux";

const ProductContent = ({ details }) => {
  const basketRef = useRef();
  window.onscroll = () => {
    if (window.pageYOffset > 160) {
      basketRef.current.style.top = "10";
    }
    return () => {};
  };
  const { amount, dir } = GetText("product");
  const currentUser = useSelector((state) => state.user.currentUser);
  const cart = useSelector((state) => state.cart);

  return (
    <div className={`productContent }`}>
      <div className="pcContent">
        <div style={{ flex: "4" }}>
          <PIntroduction />
          <Comments />
        </div>
        <div className="basDetails " ref={basketRef}>
          <span>{amount.cart} </span>
          <div className="location" style={{ direction: dir ? "rtl" : "ltr" }}>
            <span> {amount.youramount}</span>
            <span
              style={{
                color: "green",
                fontWeight: "600",
                direction: dir ? "rtl" : "ltr",
                gap: "10px",
                display: "flex",
              }}
            >
              <span>
                {currentUser ? currentUser.amount.toLocaleString() : 0}
              </span>
              {amount.toman}{" "}
            </span>
          </div>
          <div className="your" style={{ direction: dir ? "rtl" : "ltr" }}>
            {amount.yourBuy}
            <span>{cart.total}</span>
            <span>{amount.toman}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductContent;
