import { useNavigate, useParams } from "react-router-dom";
import "./failure.scss";
import { useState } from "react";
import useFetch from "../../functions/useFetch";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import moment from "jalali-moment";
import { useTranslation } from "react-i18next";

const Failure = () => {
  const [payment, setPayment] = useState({});
  const paymentId = useParams().id;
  const price = 36500000;
  const date = new Date(Date.now());
  const { data, error, isFetching } = useFetch("/pay/findpayment/" + paymentId);

  const navigate = useNavigate();
  const lan = useTranslation().i18n.language;
  const timeForm = (lan) => {
    if (lan == "fa") return "fa";
    return "en";
  };

  return (
    !isFetching &&
    !error && (
      <div className="failure">
        <div className="failContainer">
          <CloseOutlinedIcon className="icon" />
          <div className="paymentDetails">
            <h1>پرداخت انجام نشد</h1>
            <div className="result">
              <div className="resultItem">
                <span>مبلغ تراکنش</span>
                <span>{data.amount.toLocaleString() + " " + "تومان"}</span>
              </div>
              <div className="resultItem">
                <span>وضعیت تراکنش</span>
                <span>انحام نشد</span>
              </div>
              <div className="resultItem">
                <span>تاریخ انجام تراکنش</span>
                <span>
                  {moment(data.createdAt)
                    .locale(timeForm(lan))
                    .format("hh:mm:ss YYYY.MM.DD")}
                </span>
              </div>
              <div className="resultItem">
                <span style={{ margin: "auto" }}>
                  در داشبورد وضعیت سفارش خود را پیگیری نمایید
                </span>
              </div>
            </div>
          </div>
          <div className="gohomeBtn" onClick={() => navigate("/")}>
            بازگشت به صفحه اصلی
          </div>
        </div>
      </div>
    )
  );
};

export default Failure;
