import { useParams, useNavigate } from "react-router-dom";
import "./success.scss";
import CheckIcon from "@mui/icons-material/Check";
import { useState } from "react";
import useFetch from "../../functions/useFetch";
import moment from "jalali-moment";
import { useTranslation } from "react-i18next";

const Success = () => {
  const [payment, setPayment] = useState({});
  const paymentId = useParams().id;
  const price = 36500000;
  const date = new Date(Date.now());
  const { data, error, isFetching } = useFetch("/pay/findpayment/" + paymentId);
  const navigate = useNavigate();

  const lan = useTranslation().i18n.language;

  const timeForm = (lan) => {
    if (lan == "fa") return "fa";
    return "en";
  };

  return (
    !isFetching &&
    !error && (
      <div className="success">
        <div className="succContainer">
          <CheckIcon className="icon" />
          <div className="paymentDetails">
            <h1>پرداخت موفق </h1>
            <div className="result">
              <div className="resultItem">
                <span>مبلغ تراکنش</span>
                <span>{data.amount.toLocaleString() + " " + "ریال"}</span>
              </div>
              <div className="resultItem">
                <span>وضعیت تراکنش</span>
                <span>انحام شد</span>
              </div>
              <div className="resultItem">
                <span>تاریخ انجام تراکنش</span>
                <span>
                  {moment(data.createdAt)
                    .locale(timeForm(lan))
                    .format("YYY.MM.DD HH:mm:ss")}
                </span>
              </div>
              <div className="resultItem">
                <span>شماره پیگیری</span>
                <span>{data.trackingId}</span>
              </div>
              <div className="resultItem">
                <span>از کارت:</span>
                <span className="cart">{data.card}</span>
              </div>
            </div>
          </div>
          <div className="gohomeBtn" onClick={() => navigate("/")}>
            بازگشت به صفحه اصلی
          </div>
        </div>
      </div>
    )
  );
};

export default Success;
