import "./cartItem.scss";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import GetText from "../../functions/getTexts";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
  ADD_CART,
  DECREASE_CART,
  REMOVE_CART,
  INCREASE_CART,
} from "../../redux/cartRedux";

const CartItem = ({ data }) => {
  const { count, disCount, price, img, number, name, _id } = data;
  const { dir } = GetText("cart");

  const { i18n } = useTranslation();
  const lan = i18n.language;
  const dispatch = useDispatch();

  const calculateDis = (p, d, n) => {
    if (d !== 0) {
      return (p - (p * d) / 100) * n;
    }
    return p * n;
  };

  const addTocart = () => {
    dispatch(INCREASE_CART({ _id: _id, number: 1, count }));
  };

  const lessTocart = () => {
    dispatch(DECREASE_CART({ _id: _id }));
  };

  const handleRemove = () => {
    dispatch(REMOVE_CART({ _id: _id }));
  };

  const baseUrl = process.env.REACT_APP_IMAGES_SOURCE;
  console.log(baseUrl, "INLCIENT TO TEST");
  return (
    <div className={dir ? "cartItem" : "cartItem-ltr"}>
      <div className="itemImg">
        <img src={img ? baseUrl + img[lan] : "/images/slider1.jpg"} alt="" />
      </div>
      <div className="itemDetails">
        <div className="top">
          <div className="pname">{name[lan]}</div>
          {disCount !== 0 && <div className="discount">{disCount}%</div>}
          <DeleteIcon className="icon" onClick={handleRemove} />
        </div>
        <div className="bottom">
          <div className="price">{price.toLocaleString()}</div>
          <div className="amount">
            {calculateDis(price, disCount, number).toLocaleString()}
          </div>
          <div className="btns">
            <AddIcon className="icon" fontSize="small" onClick={addTocart} />
            <span>{number} عدد</span>
            <RemoveIcon
              className="icon"
              fontSize="small"
              onClick={lessTocart}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CartItem;
