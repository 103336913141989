export const hiveData = [
  {
    type: "title",
    value: "داخل کندو",
  },
  {
    type: "subtitle",
    value: "مستعمرات ",
  },
  {
    type: "text",
    value:
      "زنبورهای عسل در کلنی هایی زندگی می کنند که اغلب توسط زنبورداران نگهداری می شوند. کندوی زنبور عسل مدرن از یک سری جعبه تشکیل شده است که روی هم قرار گرفته اند. سپس قاب هایی در این جعبه ها قرار داده می شود تا زنبورها لانه زنبوری مومی را بسازند. یک کلنی به طور کلی شامل یک ماده مولد ملکه، چند هزار پهباد نر و جمعیت زیادی زنبورهای ماده عقیم کارگر است.",
  },
];
