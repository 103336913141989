import "./comment.scss";
import GetText from "../../functions/getTexts";
import useFetch from "../../functions/useFetch";
import TimeAgo from "timeago-react";

const Comment = ({ last, userDetail, text, time }) => {
  const { dir } = GetText("comments");

  const baseurl = process.env.REACT_APP_IMAGES_SOURCE;
  return (
    <div
      className={dir ? "comment" : "comment-ltr"}
      style={{ borderBottom: last && "none" }}
    >
      <div className="userProfile">
        <img
          src={
            userDetail.img ? baseurl + userDetail.img : "/images/noimage.jpg"
          }
          alt=""
        />
      </div>
      <div className="commentContent">
        <div className="user-name">
          {userDetail.phone}
          <div className={dir ? "rtl" : "ltr"}>
            {<TimeAgo datetime={time} style={{ fontSize: ".7em" }} />}
          </div>
        </div>
        <p>{text}</p>
      </div>
    </div>
  );
};

export default Comment;
