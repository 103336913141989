import { useSelector } from "react-redux";
import useFetch from "../../../functions/useFetch";
import OrderItem from "../orderItem/OrderItem";
import "./vallet.scss";
import { publicRequest } from "../../../functions/request";
import { handleError } from "../../../functions/errorHandling";
import { toast } from "react-toastify";
import { useState } from "react";
import GetText from "../../../functions/getTexts";

const Vallet = () => {
  const user = useSelector((state) => state.user.currentUser);
  const [money, setMoney] = useState();

  const handleSend = () => {};

  const { vallet, dir } = GetText("dashboard");

  return (
    <div className="vallet">
      <div className="valContainer">
        <h3>{vallet.title}</h3>
        <div className="increaseAmount">
          <div className="amount">
            <h2>{vallet.amount}</h2>
            <h1>{user.amount.toLocaleString()} تومان</h1>
          </div>
          <div className="increaseBox">
            <h2>{vallet.increase}</h2>
            <input
              type="number"
              placeholder={vallet.enter}
              onChange={(e) => setMoney(e.target.value)}
              min={10000}
            />
            <div
              className="changeBtn"
              onClick={handleSend}
              style={{ marginLeft: !dir && "auto" }}
            >
              {vallet.credit}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Vallet;
