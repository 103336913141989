import ImgBanner from "../ImgBanner/ImgBanner";
import MenuList from "../menuList/MenuList";
import SearchIcon from "@mui/icons-material/Search";
import "./imgGallery.scss";
import ViewLarge from "../viewLarge/ViewLarge";
import { useState } from "react";
import GetText from "../../functions/getTexts";

const ImgGallery = () => {
  const [open, setOpen] = useState(false);

  const hadleSelect = () => {
    setOpen(true);
  };

  const { dir, menu, photoes } = GetText("room");

  return (
    <div>
      <ImgBanner src={"/images/flower.jpg"} />
      <MenuList list={menu} />
      <div className={dir ? "imgGallery" : "imgGallery-ltr"}>
        <div className="imgGContainers">
          <h1> {photoes.title}</h1>
          <h2>{photoes.subTitle}</h2>
          <p>{photoes.text}</p>
          <div className="images">
            {Array(7)
              .fill(0)
              .map(() => (
                <div className="imcontain" onClick={hadleSelect}>
                  <img src="/images/slider1.jpg" alt="" />
                  <div className="textCon">
                    <span>مشاهده</span>
                  </div>
                  <div className="magnifire">
                    <SearchIcon />
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
      <ViewLarge data={["/images/slider1.jpg"]} open={open} setOpen={setOpen} />
    </div>
  );
};

export default ImgGallery;
