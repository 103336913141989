import "./recipesMost.scss";
import RecipesItem from "../reciptItem/RecipesItem";
import { Link } from "react-router-dom";
import GetText from "../../functions/getTexts";
import useFetch from "../../functions/useFetch";
import { useEffect, useState } from "react";

const RecipesView = () => {
  const { majority, dir } = GetText("recipes");
  const [most, setMost] = useState(null);
  const [like, setLike] = useState(null);
  const [futured, setFutured] = useState(null);

  const { data, isFetching, error } = useFetch(
    "/recipe/?catogery=null&recent=false&liked=false"
  );

  useEffect(() => {
    if (data.length > 0) {
      setMost(data.sort((a, b) => b.createdAt - a.createdAt)[0]);
      setLike(data.sort((a, b) => b.like - a.like)[0]);
      setFutured(data.sort((a, b) => b.createdAt - a.createdAt)[0]);
    }
  }, [data]);

  return (
    !isFetching &&
    !error && (
      <div className="recipesMost">
        <div className="rvContainer">
          <div className="mostItem">
            <div className="headreItem">{majority.featured}</div>
            {futured && <RecipesItem width={"100%"} data={futured} />}
          </div>
          <div className="mostItem">
            <div className="headreItem">{majority.most}</div>
            {most && <RecipesItem width={"100%"} data={most} />}
          </div>
          <div className="mostItem">
            <div className="headreItem">{majority.populare}</div>
            {like && <RecipesItem width={"100%"} data={like} />}
          </div>
        </div>
      </div>
    )
  );
};

export default RecipesView;
