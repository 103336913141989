import { useEffect, useState } from "react";
import { publicRequest } from "./request";

const useFetch = (url) => {
  const [data, setData] = useState([]);
  const [error, setError] = useState(false);
  const [isFetching, setIsFetching] = useState(true);
  const [refetch, setRefetch] = useState(false);

  useEffect(() => {
    const getDate = async () => {
      try {
        const res = await publicRequest.get(url);
        setData(res.data);
        setIsFetching(false);
      } catch (error) {
        setData(error);
        setIsFetching(false);
      }
    };
    getDate();
  }, [url, refetch]);

  return { data, error, isFetching, refetch, setRefetch, setData };
};

export default useFetch;
