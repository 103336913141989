import { Link, useLocation } from "react-router-dom";
import "./productsContent.scss";
import { useEffect, useRef, useState } from "react";
import RecipesItem from "../reciptItem/RecipesItem";
import ProductsCart from "../productCard/ProductCart";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Pagination, PaginationItem } from "@mui/material";
import GetText from "../../functions/getTexts";
import { useTranslation } from "react-i18next";
import { cheackDir } from "../../functions/cheackDir";
import TabletFilter from "../tabletFilter/TabletFilter";
import { publicRequest } from "../../functions/request";
import useFetch from "../../functions/useFetch";
import Loading from "../loading/Loading";
import { legacy_createStore } from "redux";
import { combineSlices } from "@reduxjs/toolkit";

const ProductsContent = () => {
  const { productTypes, filters, textBtn, dir } = GetText("productsContent");
  const { i18n } = useTranslation();
  const [page, setPage] = useState(1);
  const [showFilters, setshowFilters] = useState(false);
  const [catoId, setCatoId] = useState([]);
  const [newest, setNewest] = useState(true);
  const [valuePrice, setValuePrice] = useState("");
  const [max, setMax] = useState(1000000000000);
  const [min, setMin] = useState(0);
  const [catogeries, setCatogeries] = useState([]);
  const [subfilter, setSubfilter] = useState({});

  const [fr, setFr] = useState(false);

  const handleChange = (e, p) => {
    setPage(p);
  };

  const handleClickOpen = () => {
    setshowFilters(true);
  };

  const handleClose = () => {
    setshowFilters(false);
  };

  const filterRef = useRef();
  window.onscroll = () => {
    return () => {};
  };

  const { price, mostest, filter, catogery } = filters;

  useEffect(() => {
    const getCatogery = async () => {
      try {
        const res = await publicRequest.get("/catogery/?type=product");
        setCatogeries(res.data);
      } catch (error) {
        console.log(error);
      }
    };
    getCatogery();
  }, []);

  const handleMaxMin = (e) => {
    if (e.target.name == "max") {
      setMax(e.target.value);
    } else {
      setMin(e.target.value);
    }
  };

  const handleCatogery = (e) => {
    let c = [];
    if (e.target.checked) {
      setCatoId([e.target.value]);
    } else {
      let c = catoId.filter((i) => i !== e.target.value);
      setCatoId(c);
    }
  };

  const { data, setData, error, isFetching, refetch, setRefetch } = useFetch(
    `/product/find?catoId=${
      catoId.length == 0 ? "all" : catoId[0]
    }&newest=${newest}&max=${max}&min=${min}&valuePrice=${valuePrice}`
  );

  //FILTER DATA BY NEWST AND CHEAPEST AND ..
  const handleSubfilter = (e) => {
    if (e.target.name == "cheapest") {
      if (e.target.checked) {
        setValuePrice("cheapest");
        setNewest(false);
      }
      setValuePrice("cheapest");
    } else if (e.target.name == "expensive") {
      if (e.target.checked) {
        setValuePrice("expensive");
        setNewest(false);
      }
    } else if (e.target.name == "fresh") {
      if (e.target.checked) {
        setNewest(true);
        setValuePrice("");
      }
    }
  };

  const calCheck = (e) => {
    if (e == "cheapest") {
      return valuePrice == "cheapest";
    } else if (e == "expensive") {
      return valuePrice == "expensive";
    } else if (e == "fresh") {
      return newest;
    }
  };

  return (
    <div className={dir ? "productsConten" : "productsConten-ltr"}>
      <div className="pcContainer">
        <ul className="producTypes">
          {/* LIST OF CATOGEYS ON HEADER OF PRODUCTS PAGE */}
          <Link to={"/products"} state={productTypes.title}>
            <li
              className={`ptItem ${catoId.length == 0 && "active"}`}
              onClick={() => setCatoId([])}
            >
              {productTypes.title}
            </li>
          </Link>
          {catogeries.map((item, i) => (
            <Link to={"/products"} state={item.title[i18n.language]} key={i}>
              <li
                className={`ptItem ${catoId.includes(item._id) && "active"}`}
                value={item._id}
                onClick={(e) => setCatoId([item._id])}
              >
                {item.title[i18n.language]}
              </li>
            </Link>
          ))}
          <span className="filbtn" onClick={handleClickOpen}>
            {filter}
          </span>
        </ul>
        <div className="productsResult">
          <div className="filer" ref={filterRef}>
            {/* CATOGERY SECTION */}
            <div className="filterItem">
              <div className="title">{catogery.title}</div>
              <div className="items">
                {catogeries.map((vlu, i) => (
                  <label className="item" key={i}>
                    <input
                      type="checkbox"
                      checked={catoId.includes(vlu._id)}
                      value={vlu._id}
                      onChange={(e) => handleCatogery(e)}
                    />
                    <span>{vlu.title[i18n.language]}</span>
                  </label>
                ))}
              </div>
            </div>

            {/* MOSTEST SECTION */}
            <div className="filterItem">
              <div className="title">{mostest.title}</div>
              <div className="items">
                {mostest.items.map((vlu, i) => (
                  <label className="item" key={i}>
                    <input
                      type="checkbox"
                      name={vlu.value}
                      checked={calCheck(vlu.value)}
                      onChange={(e) => handleSubfilter(e)}
                    />
                    <span>{vlu.title}</span>
                  </label>
                ))}
              </div>
            </div>
            {/* PRICE SECTION */}
            <div className="filterItem">
              <div className="title">{price.title}</div>
              <div className="items">
                {price.items.map((vlu, i) => (
                  <label className="item" key={i}>
                    <input
                      type="number"
                      placeholder={vlu.title}
                      name={vlu.value}
                      onChange={(e) => handleMaxMin(e)}
                    />
                  </label>
                ))}
              </div>
            </div>
            <div className="search">{textBtn}</div>
          </div>
          {/* THE RESULTS BASE OF SELECTED FILTERS */}

          {/* filters when we are in smaller than tablet  */}
          <div className="results">
            <h1>
              {catoId.length == 0
                ? productTypes.title
                : catogeries.find((c) => c._id == catoId[0]).title[
                    i18n.language
                  ]}
            </h1>
            <div className="resContainer">
              {isFetching ? (
                <Loading load={true} />
              ) : (
                data?.slice((page - 1) * 12, page * 12).map((item, i) => {
                  return (
                    <ProductsCart
                      width={"30%"}
                      key={i}
                      details={item}
                      dir={dir}
                    />
                  );
                })
              )}
            </div>
          </div>
        </div>
        {/* PAGINATION SECTION */}
        <div className="pagination">
          <Pagination
            count={Math.ceil(data?.length / 12)}
            renderItem={(item, i) => (
              <PaginationItem
                slots={{ previous: ArrowForwardIcon, next: ArrowBackIcon }}
                {...item}
                key={i}
              />
            )}
            onChange={handleChange}
          />
        </div>
        {showFilters && (
          <TabletFilter
            open={showFilters}
            closeModal={handleClose}
            setValuePrice={setValuePrice}
            setNewest={setNewest}
            newest={newest}
            valuePrice={valuePrice}
            setCatoId={setCatoId}
            catoId={catoId}
            setMax={setMax}
            setMin={setMin}
          />
        )}
      </div>
    </div>
  );
};

export default ProductsContent;
