import Announcment from "../../componnts/annoncment/Announcment";
import BlogsContent from "../../componnts/blogsContent/BlogsContent";
import Footer from "../../componnts/footer/Footer";
import Navbar from "../../componnts/navbar/Navbar";
import "./blogs.scss";

const Blogs = () => {
  const isloading = true;
  return (
    <div className="home">
      <Navbar />
      <BlogsContent />
      <Footer />
      <Announcment />
    </div>
  );
};

export default Blogs;
