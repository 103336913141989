import { useEffect, useState, useTransition } from "react";
import GetText from "../../functions/getTexts";
import useFetch from "../../functions/useFetch";
import "./blog.scss";
import { useTranslation } from "react-i18next";
import { adminRequest } from "../../functions/request";
import { Link } from "react-router-dom";

export const Blog = () => {
  const [blog, setBlog] = useState(null);
  const { title, textbtn } = GetText("blog");

  const { data, error, isFetching } = useFetch("/blog");
  useEffect(() => {
    !isFetching && setBlog(data[0]);
  }, [data]);

  const lan = useTranslation().i18n.language;
  const baseUrl = process.env.REACT_APP_IMAGES_SOURCE;

  return (
    blog && (
      <div className="blog">
        <div className="blogContainer">
          <div className="description">
            <div className="blogTitle">{title} </div>
            <div className="titr">{blog.topic[lan]}</div>
            <p>{blog.contents[0].text[lan]}</p>
            <Link to={"/blogs/" + blog._id}>
              <div className="learnMore">{textbtn}</div>
            </Link>
          </div>
          <div className="imgBLog">
            <img src={baseUrl + blog.img[lan]} alt="" />
          </div>
        </div>
      </div>
    )
  );
};

export default Blog;
