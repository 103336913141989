import "./pIntroduction.scss";
import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import TurnedInNotIcon from "@mui/icons-material/TurnedInNot";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import { useEffect, useState } from "react";
import ViewLarge from "../viewLarge/ViewLarge";
import GetText from "../../functions/getTexts";
import useFetch from "../../functions/useFetch";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { ADD_CART, CLEAN_CART } from "../../redux/cartRedux";
import { publicRequest } from "../../functions/request";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";

const PIntroduction = () => {
  const [openViewL, setOpenViewL] = useState(false);
  const { details, dir } = GetText("product");
  const { pUnit } = GetText("Unit");
  const [productNumber, setProductNumber] = useState(1);
  const productId = useParams().id;
  const { i18n } = useTranslation();
  const lan = i18n.language;
  const dispatch = useDispatch();
  const baseUrl = process.env.REACT_APP_IMAGES_SOURCE;

  const { data, error, isFetching } = useFetch("/product/find/" + productId);

  const addToCart = () => {
    data.count >= productNumber
      ? dispatch(
          ADD_CART({
            ...data,
            number: productNumber,
          })
        )
      : toast.error("درخواست شما بیشتر از حد مجاز است!");
  };

  return (
    !isFetching && (
      <>
        <Helmet>
          <title>{`${data.name[lan]}`}</title>
          <meta name="description" content={`${data.name[lan]}`} />
          <meta name="kewords" content="عسل , پنیر , " />
        </Helmet>
        <div className={dir ? "pIntroduction" : "pIntroduction-ltr"}>
          <div className="header">
            <h4>{data?.name[lan]}</h4>
            <div className="commentsCounter">
              <StarIcon className="icon fill" />
              <StarIcon className="icon fill" />
              <StarIcon className="icon fill" />
              <StarBorderIcon />
              <StarBorderIcon />
            </div>
          </div>
          <div className="picontent">
            <div className="imgaes">
              <div className="mainimg">
                <img
                  src={baseUrl + data?.img[lan]}
                  alt=""
                  onClick={() => setOpenViewL(true)}
                />
              </div>
              <div className="imageGallery">
                {Object.values(data.img).map((pic, i) => {
                  return (
                    <div className="img-gItem" key={i}>
                      <img src={baseUrl + pic} alt="" />
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="details">
              <div className="top">
                <span className="price">
                  <span>{data?.price.toLocaleString()}</span>
                  {/* <div>{details.toman}</div> */}
                  <div>{pUnit}</div>
                </span>
                <div className="garanti"> {details.garanti} </div>
              </div>
              <div className="center">
                <div className="status"> {details.available}</div>
                <div className="desc">{data?.description[lan]}</div>
                <div className="btns">
                  <div className="counter-btns">
                    <AddIcon
                      style={{ cursor: "pointer" }}
                      onClick={() => setProductNumber(productNumber + 1)}
                    />
                    <span className="counter">{productNumber}</span>
                    <RemoveIcon
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        setProductNumber(
                          productNumber > 1 ? productNumber - 1 : 1
                        )
                      }
                    />
                  </div>
                  <div className="add-btn" onClick={addToCart}>
                    {" "}
                    {details.add}{" "}
                  </div>
                </div>
              </div>
              <div className="bottom">
                <span> {details.send} </span>
              </div>
            </div>
          </div>
        </div>
        <ViewLarge
          open={openViewL}
          setOpen={setOpenViewL}
          data={Object.values(data.img)}
        />
      </>
    )
  );
};

export default PIntroduction;
