import "./changeLan.scss";
import LanguageIcon from "@mui/icons-material/Language";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { languages } from "./data";
import { useEffect, useState } from "react";

const ChangeLan = () => {
  const [showlan, setShowlan] = useState(false);
  const { t, i18n } = useTranslation();

  const changeLanguage = (lan) => {
    i18n.changeLanguage(lan);
    // setShowlan(false);
  };

  return (
    <div
      className="changeLan"
      style={{ direction: "ltr" }}
      onMouseEnter={() => setShowlan(true)}
      onMouseLeave={() => setShowlan(false)}
      onClick={() => setShowlan(!showlan)}
    >
      <LanguageIcon className="icon" />
      <span>{i18n.language}</span>
      {/* <KeyboardArrowDownIcon /> */}
      <div className="langusges" style={{ display: showlan ? "flex" : "none" }}>
        {languages.map((item, i) => {
          return (
            <div
              className="lanItem"
              key={i}
              onClick={() => changeLanguage(item.code)}
            >
              <img src={item.flag} alt="" />
              <span>{item.language}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ChangeLan;
