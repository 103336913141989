import { useEffect, useState } from "react";
import "./loading.scss";

const Loading = ({ load }) => {
  const [index, setIndex] = useState(2);
  const [loadMode, setLoadMode] = useState(load);

  useEffect(() => {
    setLoadMode(load);
  }, [load]);

  setTimeout(() => {
    setIndex(index > 0 ? index - 1 : 2);
  }, 250);

  const array = Array(3).fill(1);
  return (
    <>
      {loadMode && (
        <div className="loading">
          <div className="loader">
            <img src="/images/logo2.png" alt="" />
            <div className="cirlcels">
              {array.map((item, i) => {
                return (
                  <div
                    className={`cirItem ${index == i && "active"}`}
                    key={i}
                  ></div>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Loading;
