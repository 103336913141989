import { Link } from "react-router-dom";
import "./mobileNav.scss";
import { useEffect, useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

export const MobileNav = ({ navbarList, showMenu }) => {
  const [showMore, setShowMore] = useState(false);
  const [activeMen, setActiveMenu] = useState(false);

  useEffect(() => {
    setActiveMenu(showMenu);
  }, [showMenu]);

  return (
    <div className="mobileNav">
      <div className={`mnavItems ${activeMen && "active"}`}>
        {navbarList?.map((nitem, i) => {
          return (
            <Link
              style={{ color: "inherit", textDecoration: "none" }}
              to={`${nitem.link}`}
              key={i}
              state={"همه محصولات"}
            >
              <div
                className="menuItem"
                onMouseEnter={() => {
                  setShowMore(nitem.title == "products||محصولات" && true);
                }}
                onMouseLeave={() => {
                  setShowMore(nitem.title == "products||محصولات" && false);
                }}
              >
                {nitem.title}
                {nitem.title == "products||محصولات" && (
                  <KeyboardArrowDownIcon />
                )}
                {/* {nitem.title == "products||محصولات" && (
       
                  )} */}
              </div>
            </Link>
          );
        })}
      </div>
    </div>
  );
};
