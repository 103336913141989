import { useTranslation } from "react-i18next";
import GetText from "../../functions/getTexts";
import useFetch from "../../functions/useFetch";
import "./recipesHead.scss";
import { Link } from "react-router-dom";

const RecipesHead = () => {
  const { head, dir } = GetText("recipes");

  const { data, error, isFetching } = useFetch("/recipe/getsample");
  const baseUrl = process.env.REACT_APP_IMAGES_SOURCE;
  const lan = useTranslation().i18n.language;

  return (
    !isFetching &&
    !error && (
      <div className="rHead">
        <img src={baseUrl + data[0].img} alt="" />
        <div className="moreDetail">
          <div className="content">
            <div className="title">{data[0].topic[lan]}</div>
            <Link to={"/recipe/" + data[0]._id}>
              <div className="more-btn"> {head.textbtn} </div>
            </Link>
          </div>
        </div>
      </div>
    )
  );
};

export default RecipesHead;
