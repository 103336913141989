import { useTranslation } from "react-i18next";
import useFetch from "../../functions/useFetch";
import "./search.scss";
import SearchItem from "../searchItem/SearchItem";
import { useEffect, useRef } from "react";
import GetText from "../../functions/getTexts";

const Search = ({ searched, setSearched }) => {
  const lan = useTranslation().i18n.language;
  const { data, isFetching, error } = useFetch(
    `/product/search/?lan=${lan}&searched=${searched}`
  );

  const { noResult } = GetText("searchItem");

  return (
    !isFetching && (
      <div className="serach">
        <div className="searchContainer">
          <div className="box">
            {data.length > 0 ? (
              data.map((d, i) => (
                <SearchItem data={d} setSearched={setSearched} key={i} />
              ))
            ) : (
              <div className="noItems">{noResult}</div>
            )}
          </div>
        </div>
      </div>
    )
  );
};

export default Search;
