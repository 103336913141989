import { useSelector } from "react-redux";
import "./ticket.scss";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import KeyboardBackspaceOutlinedIcon from "@mui/icons-material/KeyboardBackspaceOutlined";
import FolderOutlinedIcon from "@mui/icons-material/FolderOutlined";
import { useEffect, useRef, useState } from "react";
import useFetch from "../../../functions/useFetch";
import { handleError } from "../../../functions/errorHandling";
import { publicRequest } from "../../../functions/request";
import { toast } from "react-toastify";
import GetText from "../../../functions/getTexts";
import { Link, useParams } from "react-router-dom";
import moment from "jalali-moment";
import Message from "../../message/Message";
import { io } from "socket.io-client";

function Ticket() {
  const [text, setText] = useState("");
  const [ticket, setTicket] = useState(null);
  const [messages, setMessages] = useState([]);
  const [arrivalMessage, setArrivalMessage] = useState({});
  const user = useSelector((state) => state.user.currentUser);
  const ticketId = useParams().id;
  const socket = useRef();
  const scrollRef = useRef();

  const scroolToEnd = () => {
    scrollRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const { data, error, isFetching, refetch, setRefetch } = useFetch(
    "/message/" + ticketId
  );

  useEffect(() => {
    data.length > 0 && setMessages(data);
  }, [data]);

  useEffect(() => {
    scroolToEnd();
  }, [messages]);

  const updateMessage = async (ticketId) => {
    try {
      await publicRequest.put("/message/unread/" + ticketId);
      console.log("Doned");
      setRefetch(!refetch);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    socket.current = io(process.env.REACT_APP_SOCKET_URL);
    socket.current?.on("getMessage", async (mess) => {
      console.log(mess, "new mess from admin");
      setMessages((prev) => {
        return [...prev, mess];
      });
      await updateMessage(ticketId);
    });
  }, []);

  useEffect(() => {
    socket.current?.emit("addUser", user._id);
    socket.current?.on("users", (users) => console.log(users, "userss"));
  }, [user]);

  const getTicket = async () => {
    try {
      const res = await publicRequest.get("/ticket/?ticketId=" + ticketId);
      setTicket(res.data[0]);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getTicket();
  }, [ticketId]);

  const sendMessage = async () => {
    let newMessage = {};
    if (ticket?.adminId) {
      newMessage = {
        sender: user._id,
        reciver: ticket?.adminId,
        ticketId,
        text,
      };
    } else {
      getTicket();
      newMessage = {
        sender: user._id,
        reciver: ticket.adminId ? ticket.adminId : console.log("error injst"),
        ticketId,
        text,
      };
    }
    try {
      if (text) {
        const res = await publicRequest.post("/message", newMessage);
        setMessages((prev) => [...prev, res.data]);
        ticket.adminId && socket.current?.emit("sendMessage", newMessage);
        setText("");
      }
    } catch (error) {
      handleError(error);
    }
  };

  const hanldeKeyDown = (e) => {
    if (e.key == "Enter") {
      sendMessage();
    }
  };

  const { messager, dir } = GetText("dashboard");

  return (
    <div className="ticket">
      <div className="header">
        <div className="duppName">پشتیبانی</div>
        <Link to={"/dashboard/tickets"}>
          <KeyboardBackspaceOutlinedIcon />
        </Link>
      </div>
      <div className="messages">
        {messages.length > 0 ? (
          <>
            {messages.map((d, i) => {
              return (
                <Message m={d.text} own={d.sender == user._id} read={d.read} />
              );
            })}
            <div ref={scrollRef} />
          </>
        ) : (
          messager.startChat
        )}
      </div>
      <div className="sendeBox">
        <SendOutlinedIcon
          fontSize="large"
          style={{ cursor: "pointer", color: "lightblue" }}
          onClick={sendMessage}
        />
        <input
          type="text"
          value={text}
          placeholder={messager.write}
          onChange={(e) => setText(e.target.value)}
          style={{ direction: !dir && "ltr" }}
          onKeyDown={(e) => hanldeKeyDown(e)}
        />
      </div>
    </div>
  );
}

export default Ticket;
