import { useSelector } from "react-redux";
import "./orderItem.scss";
import useFetch from "../../../functions/useFetch";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import KeyboardArrowUpOutlinedIcon from "@mui/icons-material/KeyboardArrowUpOutlined";
import { useState } from "react";
import moment from "jalali-moment";
import GetText from "../../../functions/getTexts";
import { useTranslation } from "react-i18next";
import { publicRequest } from "../../../functions/request";
import { handleError } from "../../../functions/errorHandling";
import { useNavigate } from "react-router-dom";

const OrderItem = ({ data }) => {
  const [showDetails, setShowDetails] = useState(false);
  const user = useSelector((state) => state.user.currentUser);
  const sum = (p) => {
    const { number, price } = p;
    if (p.disCount != 0) {
      let total = number * price - (number * price * p.discount) / 100;
      return total;
    } else {
      return number * price;
    }
  };
  const { orders, dir } = GetText("dashboard");
  const lan = useTranslation().i18n.language;
  const timeForm = (lan) => {
    if (lan == "fa") return "fa";
    return "en";
  };
  const navigate = useNavigate();

  const sendTicket = () => {
    const newTicket = async () => {
      const ticket = {
        userId: user._id,
        orderId: data._id,
      };
      try {
        const res = await publicRequest.post("/ticket", ticket);
        console.log(res.data);
        navigate("/dashboard/ticket/" + res.data._id);
      } catch (error) {
        handleError(error);
      }
    };
    newTicket();
  };

  return (
    <div className={dir ? "orderItem" : "orderItem-ltr"}>
      <div className={`orderContainer ${showDetails && "active"} `}>
        <div className="date">
          <span>{orders.date} </span>
          <span>
            {moment(data.createdAt).locale(timeForm(lan)).format("YYYY/MM/DD")}{" "}
          </span>
        </div>
        <div className={`sendStatus ${data.status && "succ"}`}>
          {data.status ? orders.sent : orders.pending}
        </div>
        <div className="status">
          <span>{orders.pay} </span>
          <span className={data.payStatus ? "succ" : "fail"}>
            {data.payStatus ? orders.success : orders.fail}
          </span>
        </div>
        {showDetails ? (
          <KeyboardArrowUpOutlinedIcon
            onClick={() => setShowDetails(false)}
            className="icon"
          />
        ) : (
          <KeyboardArrowDownOutlinedIcon
            onClick={() => setShowDetails(true)}
            className="icon"
          />
        )}
      </div>
      {showDetails && (
        <div className="detail">
          <div className="headre">
            <div className="tracking">
              <span>{orders.trackcode}</span>
              <h4>{data.trackId}</h4>
            </div>
            <div className="total">
              <span>{orders.sum}</span>
              <h1>{data.amount.toLocaleString()}</h1>
            </div>
            {/* <DeleteOutlinedIcon className="del" /> */}
          </div>
          <div className="producst">
            {data.products.map((p) => {
              return (
                <div className="pItem">
                  <div className="name">{p.name}</div>
                  <div className="price">{p.price.toLocaleString()}</div>
                  {p.discount != 0 && (
                    <div className="dis">{p.discount + "%"}</div>
                  )}
                  <div className="count">{p.number} عدد</div>
                  <div className="sum">{sum(p).toLocaleString()} تومان</div>
                </div>
              );
            })}{" "}
          </div>
          <div className="ticketBtn" onClick={sendTicket}>
            ارسال تیکت
          </div>
        </div>
      )}
    </div>
  );
};

export default OrderItem;
