import {
  Navigate,
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";
import "./App.css";
import Home from "./pages/home/Home";
import StaticLayout from "./layouts/static";
import AboutUs from "./pages/aboutUS/AboutUs";
import Recipes from "./pages/recipes/Recipes";
import Search from "./pages/search/Search";
import RecipeOne from "./componnts/reciepOne/RecipeOne";
import Recipe from "./pages/recipe/Recipe";
import Products from "./pages/products/Products";
import Product from "./pages/product/Product";
import { useSelector } from "react-redux";
import InsideHive from "./componnts/insideHive/InsideHive";
import LocatorPage from "./pages/locatorPage/LocatorPage.jsx";
import Videos from "./componnts/videos/Videos.jsx";
import ImgGallery from "./componnts/imgGallery/ImgGallery.jsx";
import Static from "./pages/static/Static.jsx";
import Blogs from "./pages/blogs/Blogs.jsx";
import Blog from "./pages/blog/Blog.jsx";
import SearchContextProvider from "./context/SearchContext.js";
import Success from "./pages/success/Success.jsx";
import Pay from "./pages/pay/Pay.jsx";
import Failure from "./pages/failure/Failure.jsx";
import DashLayout from "./layouts/dashboard.js";
import Orders from "./componnts/dashboard/orders/Orders.jsx";
import Profile from "./componnts/dashboard/profile/Profile.jsx";
import ChangePass from "./componnts/dashboard/changePass/ChangePass.jsx";
import Vallet from "./componnts/dashboard/vallet/Vallet.jsx";
import Tickets from "./componnts/dashboard/tickets/Tickets.jsx";
import Ticket from "./componnts/dashboard/ticket/Ticket.jsx";
import { useTranslation } from "react-i18next";
import { cheackDir } from "./functions/cheackDir.js";

function App() {
  const user = useSelector((state) => state.user.currentUser);
  const { i18n } = useTranslation();
  const dir = cheackDir(i18n.language);
  //PROTECT ROUTES, FOR EXAMPLE DASHBOARD!
  const ProtectedRoutes = ({ children }) => {
    if (!user) {
      return <Navigate to={"/"} />;
    } else {
      return children;
    }
  };

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Home />,
    },
    {
      path: "/pay",
      element: <Pay />,
    },
    {
      path: "/blogs",
      element: <Blogs />,
    },
    {
      path: "/success/:id",
      element: <Success />,
    },
    {
      path: "/failure/:id",
      element: <Failure />,
    },
    {
      path: "/blogs/:id",
      element: <Blog />,
    },
    {
      path: "/locator",
      element: <LocatorPage />,
    },
    {
      path: "/aboutus",
      element: <AboutUs />,
    },
    {
      path: "/recipes",
      element: <Recipes />,
    },
    {
      path: "/recipe/:id",
      element: <Recipe />,
    },
    {
      path: "/recipes/search",
      element: <Search />,
    },
    {
      path: "/products",
      element: <Products />,
    },
    {
      path: "/product/:id",
      element: <Product />,
    },
    {
      path: "/dashboard",
      element: (
        <ProtectedRoutes>
          <DashLayout />
        </ProtectedRoutes>
      ),
      children: [
        {
          path: "/dashboard",
          element: <Profile />,
        },
        {
          path: "/dashboard/tickets",
          element: <Tickets />,
        },
        {
          path: "/dashboard/ticket/:id",
          element: <Ticket />,
        },
        {
          path: "/dashboard/orders",
          element: <Orders />,
        },
        {
          path: "/dashboard/changePass",
          element: <ChangePass />,
        },
        {
          path: "/dashboard/vallet",
          element: <Vallet />,
        },
      ],
    },
    {
      path: "/abouthoney/:articleId",
      element: <Static />,
    },
    {
      path: "/bees-sustainability/:articleId",
      element: <Static />,
    },
    {
      path: "/newsroom",
      element: <StaticLayout />,
      children: [
        { path: "/newsroom/videos", element: <Videos /> },
        {
          path: "/newsroom/photoes",
          element: <ImgGallery />,
        },
        {
          path: "/newsroom/champing",
          element: <InsideHive />,
        },
      ],
    },
  ]);

  return (
    <div className={`App ${dir ? "per-font" : "en-font"}`}>
      <SearchContextProvider>
        <RouterProvider router={router} />
      </SearchContextProvider>
    </div>
  );
}
export default App;
