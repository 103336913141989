import Announcment from "../../componnts/annoncment/Announcment";
import BlogContent from "../../componnts/blogContent/BlogContent";
import BlogsContent from "../../componnts/blogsContent/BlogsContent";
import Footer from "../../componnts/footer/Footer";
import Navbar from "../../componnts/navbar/Navbar";
import "./blog.scss";

const Blog = () => {
  const isloading = true;
  return (
    <div className="">
      <Navbar />
      <BlogContent />
      <Footer />
      <Announcment />
    </div>
  );
};

export default Blog;
