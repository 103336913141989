import React, { useEffect, useState } from "react";

import GetText from "../../functions/getTexts";
import "./tabletFilters.scss";
import { Dialog, Slide } from "@mui/material";
import { publicRequest } from "../../functions/request";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const TabletFilter = ({
  open,
  closeModal,
  setValuePrice,
  setNewest,
  valuePrice,
  newest,
  setCatoId,
  catoId,
  setMax,
  setMin,
}) => {
  const [catogeries, setCatogeries] = useState([]);

  const { productTypes, filters, textBtn, dir } = GetText("productsContent");
  const { price, mostest, catogery } = filters;

  //   window.onscroll = () => {
  //     if (window.innerWidth > 768) {
  //       closeModal();
  //       console.log(window.window.innerWidth);
  //     }
  //     return () => {};
  //   };

  useEffect(() => {
    // window.innerWidth > 768 && closeModal();
    console.log(window.innerWidth, "width");
  }, [window.innerWidth]);

  useEffect(() => {
    const getCatogery = async () => {
      try {
        const res = await publicRequest.get("/catogery/?type=product");
        setCatogeries(res.data);
      } catch (error) {
        console.log(error);
      }
    };
    getCatogery();
  }, []);

  const handleCatogery = (e) => {
    let c = [];
    if (e.target.checked) {
      setCatoId([e.target.value]);
    } else {
      let c = catoId.filter((i) => i !== e.target.value);
      setCatoId(c);
    }
  };

  //FILTER DATA BY NEWST AND CHEAPEST AND ..
  const handleSubfilter = (e) => {
    if (e.target.name == "cheapest") {
      if (e.target.checked) {
        setValuePrice("cheapest");
        setNewest(false);
      }
      setValuePrice("cheapest");
    } else if (e.target.name == "expensive") {
      if (e.target.checked) {
        setValuePrice("expensive");
        setNewest(false);
      }
    } else if (e.target.name == "fresh") {
      if (e.target.checked) {
        setNewest(true);
        setValuePrice("");
      }
    }
  };

  const calCheck = (e) => {
    if (e == "cheapest") {
      return valuePrice == "cheapest";
    } else if (e == "expensive") {
      return valuePrice == "expensive";
    } else if (e == "fresh") {
      return newest;
    }
  };
  const handleMaxMin = (e) => {
    if (e.target.name == "max") {
      setMax(e.target.value);
    } else {
      setMin(e.target.value);
    }
  };

  const { i18n } = useTranslation();

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={closeModal}
      aria-describedby="alert-dialog-slide-description"
    >
      <div className={dir ? "tabletFilter" : "tabletFilter-ltr"}>
        {/* CATOGERY SECTION */}
        <div className="filterItem">
          <div className="title">{catogery.title}</div>
          <div className="items">
            {catogeries.map((vlu, i) => (
              <label className="item" key={i}>
                <input
                  type="checkbox"
                  checked={catoId.includes(vlu._id)}
                  value={vlu._id}
                  onChange={(e) => handleCatogery(e)}
                />
                <span>{vlu.title[i18n.language]}</span>
              </label>
            ))}
          </div>
        </div>

        {/* MOSTEST SECTION */}
        <div className="filterItem">
          <div className="title">{mostest.title}</div>
          <div className="items">
            {mostest.items.map((vlu, i) => (
              <label className="item" key={i}>
                <input
                  type="checkbox"
                  name={vlu.value}
                  checked={calCheck(vlu.value)}
                  onChange={(e) => handleSubfilter(e)}
                />
                <span>{vlu.title}</span>
              </label>
            ))}
          </div>
        </div>
        {/* PRICE SECTION */}
        <div className="filterItem">
          <div className="title">{price.title}</div>
          <div className="items">
            {price.items.map((vlu, i) => (
              <label className="item" key={i}>
                <input
                  type="number"
                  placeholder={vlu.title}
                  name={vlu.value}
                  onChange={(e) => handleMaxMin(e)}
                />
              </label>
            ))}
          </div>
        </div>
        <div className="search" onClick={() => closeModal(true)}>
          {textBtn}
        </div>
      </div>
    </Dialog>
  );
};

export default TabletFilter;
