import { useParams } from "react-router-dom";
import useFetch from "../../functions/useFetch";
import "./blogContent.scss";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import GetText from "../../functions/getTexts";

const BlogContent = () => {
  const blogId = useParams().id;
  const { data, error, isFetching } = useFetch("/blog/" + blogId);
  const lan = useTranslation().i18n.language;

  const { dir } = GetText("navbar");

  const baseUrl = process.env.REACT_APP_IMAGES_SOURCE;
  return (
    !isFetching && (
      <div className={`${dir ? "blogContent" : "blogContent-ltr"}`}>
        <Helmet>
          <title>{data.topic[lan]}</title>
          <meta name="description" content="" />
          <meta name="keywords" content="" />
        </Helmet>
        <div className="bcContainer">
          <h1 className="title">{data.topic[lan]}</h1>
          <img src={baseUrl + data.img[lan]} alt="" />
          <div className="blogtext">
            {data.contents.map((d, i) => {
              return (
                <div className="btextitem">
                  <h2 className="titr">{d.title[lan]}</h2>
                  <p>{d.text[lan]}</p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    )
  );
};
export default BlogContent;
