import "./comments.scss";
import StarIcon from "@mui/icons-material/Star";
import StarOutlineIcon from "@mui/icons-material/StarOutline";
import { useEffect, useState } from "react";
import GetText from "../../functions/getTexts";
import Comment from "../comment/Comment";
import { handleError } from "../../functions/errorHandling";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { publicRequest } from "../../functions/request";
import useFetch from "../../functions/useFetch";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

const Comments = () => {
  const [rates, setRates] = useState(5);
  const [bRate, setBRate] = useState(0);
  const [text, setText] = useState("");
  const { newComm, comments, dir } = GetText("product");
  const [index, setIndex] = useState(1);
  const [avRates, setAvRates] = useState(0);

  const hanldeIndex = () => {
    const commentPart = Math.ceil(data?.length / 10);
    setIndex(index < commentPart ? index + 1 : 1);
  };

  const currentUser = useSelector((state) => state.user.currentUser);
  const productId = useParams().id;

  const { data, error, isFetching, refetch, setRefetch } = useFetch(
    "/comment/?productId=" + productId
  );

  const calPercent = (i) => {
    if (data?.length > 0) {
      const counter = data?.filter((d) => d.stars == i);
      const percent = counter?.length / data?.length;
      return Math.ceil(percent * 100);
    } else {
      return 0;
    }
  };

  useEffect(() => {
    const cal = () => {
      const startArray = data?.map((d) => d.stars);
      const avrates = startArray?.reduce((a, b) => a + b) / data?.length;
      setAvRates(Math.ceil(avrates));
    };
    data?.length > 0 && cal();
  }, [data]);

  const handleRate = (r) => {
    setRates(r + 1);
    setBRate(r + 1);
  };

  const whenEnter = (r) => {
    setBRate(rates);
    setRates(r + 1);
  };

  const whenLeave = (r) => {
    setRates(bRate);
  };

  const handleSend = (e) => {
    const createNew = async () => {
      const newComment = {
        text,
        productId,
        stars: rates,
        userId: currentUser?._id,
      };
      try {
        const res = await publicRequest.post("/comment", newComment);
        setRefetch(!refetch);
        toast.success("با موفقیت ثبت گردید");
        setText("");
      } catch (error) {
        handleError(error);
      }
    };
    currentUser ? createNew() : toast.error("ابتدا باید وارد حساب خود شوید");
  };

  return (
    !isFetching && (
      <div className={dir ? "comments" : "comments-ltr"}>
        <div className="head">
          <span> {newComm.title}</span>
        </div>
        <div className="cContent">
          <div className="top">
            <div className="score">
              <div className="titr"> {newComm.avrPoint}</div>
              <div className="poan">{avRates}</div>
              <div className="stars">
                {Array(5)
                  .fill(0)
                  .map((item, i) =>
                    i < avRates ? (
                      <StarIcon
                        style={{ color: "orange" }}
                        key={i}
                        onClick={() => handleRate(i)}
                        onMouseEnter={() => whenEnter(i)}
                        onMouseLeave={() => whenLeave(i)}
                      />
                    ) : (
                      <StarOutlineIcon
                        key={i}
                        onClick={() => handleRate(i)}
                        onMouseEnter={() => whenEnter(i)}
                        onMouseLeave={() => whenLeave(i)}
                      />
                    )
                  )}
              </div>
              <div className="commentCount">{newComm.userComm}</div>
              <div className="ratingStars">
                {Array(5)
                  .fill(0)
                  .map((item, i) => (
                    <div className="rating-item" key={i}>
                      <span>{i + 1 + " " + newComm.star}</span>
                      <div className="progress">
                        <div
                          className="fill"
                          style={{ width: `${calPercent(i + 1)}%` }}
                        ></div>
                      </div>
                      <div className="percent">{calPercent(i + 1)}%</div>
                    </div>
                  ))}
              </div>
            </div>
            <div className="newComment">
              <div className="titr"> {newComm.writecomm} </div>
              <div className="yourRate">
                <span>{newComm.yourScore}</span>
                <div className="rate-starts">
                  {Array(5)
                    .fill(0)
                    .map((item, i) =>
                      i < rates ? (
                        <StarIcon
                          style={{ color: "orange" }}
                          key={i}
                          onClick={() => handleRate(i)}
                          onMouseEnter={() => whenEnter(i)}
                          onMouseLeave={() => whenLeave(i)}
                        />
                      ) : (
                        <StarOutlineIcon
                          key={i}
                          onClick={() => handleRate(i)}
                          onMouseEnter={() => whenEnter(i)}
                          onMouseLeave={() => whenLeave(i)}
                        />
                      )
                    )}
                </div>
              </div>
              <textarea
                name=""
                id=""
                placeholder={newComm.placeHolder}
                onChange={(e) => setText(e.target.value)}
                value={text}
              />
              <div className="send-comment" onClick={handleSend}>
                {newComm.textbtn}
              </div>
            </div>
          </div>
          <div className="bottom">
            <div className="header">
              {comments.title} , {data?.length}
            </div>
            <div className="commentItems">
              {data?.length > 0 ? (
                data
                  ?.slice(0, index * 8)
                  .map((item, i) => (
                    <Comment
                      last={i == data?.length - 1}
                      userDetail={item.userDetail}
                      text={item.text}
                      time={item.createdAt}
                      key={i}
                    />
                  ))
              ) : (
                <span className="noComment">{comments.noComment}</span>
              )}
            </div>
            {index <= Math.ceil(data?.length / 10) && data?.length > 8 && (
              <div className="seeMore" onClick={hanldeIndex}>
                {index < Math.ceil(data?.length / 10) ? (
                  <>
                    {comments.seeMore} <KeyboardArrowDownIcon />
                  </>
                ) : (
                  <>
                    {comments.seeLess} <KeyboardArrowUpIcon />
                  </>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    )
  );
};

export default Comments;
