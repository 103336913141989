import { Children, createContext, useEffect, useState } from "react";
import useFetch from "../functions/useFetch";

export const SearchContext = createContext();

const SearchContextProvider = ({ children }) => {
  const [filter, setFilter] = useState(null);
  const [recent, setRecent] = useState(false);
  const [liked, setLiked] = useState(false);
  const [catogery, setCatogery] = useState("null");
  const [searchTopic, setSearchTopic] = useState(null);
  const [catogeries, setCatogeries] = useState([]);

  const { data, isFetching, errro } = useFetch("/catogery/?type=recipe");
  useEffect(() => {
    setCatogeries(data);
  }, [data]);

  return (
    <SearchContext.Provider
      value={{
        recent,
        liked,
        setRecent,
        setLiked,
        catogeries,
        filter,
        setFilter,
        searchTopic,
        setSearchTopic,
        catogery,
        setCatogery,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
};

export default SearchContextProvider;
