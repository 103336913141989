import "./footer.scss";
import InstagramIcon from "@mui/icons-material/Instagram";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import TelegramIcon from "@mui/icons-material/Telegram";
import GetText from "../../functions/getTexts";
import { cheackDir } from "../../functions/cheackDir";
import { useTranslation } from "react-i18next";
import { useEffect, useRef, useState } from "react";
import { handleError } from "../../functions/errorHandling";

import { publicRequest } from "../../functions/request";
import useFetch from "../../functions/useFetch";
import { Link, useLocation } from "react-router-dom";

const Footer = () => {
  const [about, setAbout] = useState({});
  const [hidde, setHidde] = useState({});
  const [img, setImg] = useState(null);
  const [licenses, setLicenses] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isTruncated, setIsTruncated] = useState(false);

  const url = useLocation().pathname.split("/")[2];

  useEffect(() => {
    setHidde(url == "ticket" ? true : false);
  }, [url]);

  const { i18n } = useTranslation();
  const {
    addressTitle,
    more,
    address,
    phoneTitle,
    copyRight,
    aboutTitle,
    email,
    para,
    socials,
    appTtile,
  } = GetText("footer");

  useEffect(() => {
    const getLogo = async () => {
      try {
        const res = await publicRequest.get(
          "/image/find/?place=logo&isSelect=true"
        );
        setImg(res.data[0]);
      } catch (error) {}
    };
    getLogo();
  }, []);

  const { data, error, isFetching } = useFetch(
    "/image/find/?place=licence&isSelect=true"
  );

  useEffect(() => {
    const getAbout = async () => {
      try {
        setLoading(true);
        const res = await publicRequest.get("/company");
        setAbout(res.data);
        setLoading(false);
      } catch (error) {
        handleError(error);
        setLoading(false);
      }
    };
    getAbout();
  }, []);

  const textRef = useRef();

  const lan = useTranslation().i18n.language;
  const less = true;
  return (
    !isFetching &&
    !error && (
      <div
        className={`${!cheackDir(i18n.language) ? "footer-ltr" : "footer"}`}
        style={{ display: hidde && "none" }}
      >
        <div className="footerContainer">
          <div className="logoSection">
            <div className="logo">
              <img
                src={
                  img
                    ? process.env.REACT_APP_IMAGES_SOURCE + img.link
                    : "/images/logo2.png"
                }
                alt="logo"
              />
            </div>
            {!loading && (
              <div className="contactDetails">
                <div className="contactItem">
                  <span>{addressTitle}</span>
                  <span>{about.address[lan]}</span>
                </div>
                <div className="contactItem">
                  <span>{phoneTitle}</span>
                  <span>{about.phone}</span>
                </div>
                <div className="contactItem">
                  <span>{email}</span>
                  <span>{about.email.support}</span>
                </div>
                <div className="contactItem">
                  <span>{email}</span>
                  <span>{about.email.info}</span>
                </div>
                <div className="contactItem">
                  <span>{email}</span>
                  <span>{about.email.sale}</span>
                </div>
              </div>
            )}
            <div className="copyRight">{copyRight}</div>
          </div>
          <div className="links">
            {!loading && (
              <div className="aboutCompany">
                <h3 className="title">{aboutTitle}</h3>
                <p>
                  {about.description[lan].slice(0, 496) + "..."}
                  {about.description[lan].length > 497 && (
                    <Link to={"/aboutus"}>
                      <span className="toabout"> {more}</span>
                    </Link>
                  )}
                </p>
              </div>
            )}
            <div className="socils">
              <h3 className="socialsTitle">{socials}</h3>
              <div className="socialItems">
                <InstagramIcon className="socialIcon" />
                <WhatsAppIcon className="socialIcon" />
                <TelegramIcon className="socialIcon" />
              </div>
            </div>
            <div className="approvals">
              <div className="appTitle">{appTtile}</div>
              {data?.slice(0, 6).map((l, i) => {
                return (
                  <Link to={l.url} key={i}>
                    <img
                      src={process.env.REACT_APP_IMAGES_SOURCE + l.link}
                      alt="license"
                      key={i}
                    />
                  </Link>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default Footer;
