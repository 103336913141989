import { toast } from "react-toastify";

export const handleError = (err) => {
  if (!err.response) {
    return toast.error("خطا در ارتباط با سرور، لحظاتی بعد امتحان نمایید");
  }
  if (err.response.status == 500) {
    toast.error("اطلاعات خواسته شده را به درستی وارد نمایید");
  } else {
    toast.error(err.response.data);
  }
};
