import { createSlice } from "@reduxjs/toolkit";

const userReducer = createSlice({
  name: "user",
  initialState: {
    currentUser: null,
    error: false,
    isFetching: false,
  },
  reducers: {
    LOGIN_START: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    LOGIN_SUCCESS: (state, action) => {
      state.currentUser = action.payload;
      state.isFetching = false;
    },
    LOGIN_FAILURE: (state) => {
      state.error = true;
      state.isFetching = false;
    },
    LOG_OUT: (state) => {
      state.currentUser = null;
    },
  },
});

export const { LOGIN_START, LOGIN_SUCCESS, LOGIN_FAILURE, LOG_OUT } =
  userReducer.actions;

export default userReducer.reducer;
