import { Link } from "react-router-dom";
import GetText from "../../functions/getTexts";
import "./aboutHoney.scss";
import EastIcon from "@mui/icons-material/East";

const AboutHoney = () => {
  const { line1, line2, para, textbtn } = GetText("aboutHoney");
  return (
    <div className="aboutHoney">
      <div className="aboutHoneyContainer">
        <div className="img">
          <img src="./images/about-honey.jpg" alt="" />
        </div>
        <div className="text">
          <div className="title">{line1} </div>
          <h1>{line2} </h1>
          <p>{para}</p>
          <div className="btn">
            <Link to={"/abouthoney/first"}>
              <span> {textbtn} </span>
            </Link>
            {/* <EastIcon className="arrow-icon" /> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutHoney;
