import { createSlice } from "@reduxjs/toolkit";
import { act } from "react";
import { toast } from "react-toastify";

const cartReducer = createSlice({
  name: "cart",
  initialState: {
    products: [],
    total: 0,
    count: 0,
  },
  reducers: {
    ADD_CART: (state, action) => {
      if (!state.products.find((p) => p._id == action.payload._id)) {
        state.products.push(action.payload);
        toast.success("به سبد اضافه شد");
      } else {
        state.products = state.products?.map((p) => {
          if (p._id == action.payload._id) {
            if (p.number + action.payload.number <= action.payload.count) {
              p.number = p.number + action.payload.number;
              toast.success("به سبد اضافه شد");
            } else {
              toast.error("درخواست شما بیشتر از حد مجاز است");
            }
          }
          return p;
        });
      }
      let proPrice = state.products.map((p) => {
        if (p.disCount == 0) {
          return p.price * p.number;
        } else {
          return (p.price - (p.price * p.disCount) / 100) * p.number;
        }
      });
      state.total = proPrice.reduce((a, b) => a + b);
    },
    INCREASE_CART: (state, action) => {
      const { _id } = action.payload;
      state.products.map((p) => {
        if (p._id == _id) {
          if (p.number + 1 <= action.payload.count) {
            p.number = p.number + 1;
          }
          return p;
        } else {
          return p;
        }
      });
      let proPrice = state.products.map((p) => {
        if (p.disCount == 0) {
          return p.price * p.number;
        } else {
          return (p.price - (p.price * p.disCount) / 100) * p.number;
        }
      });
      state.total = proPrice.reduce((a, b) => a + b);
    },
    DECREASE_CART: (state, action) => {
      const { _id } = action.payload;
      state.products.map((p) => {
        if (p._id == _id) {
          if (p.number > 1) {
            p.number = p.number - 1;
          }
          return p;
        } else {
          return p;
        }
      });
      let proPrice = state.products.map((p) => {
        if (p.disCount == 0) {
          return p.price * p.number;
        } else {
          return (p.price - (p.price * p.disCount) / 100) * p.number;
        }
      });
      state.total = proPrice.reduce((a, b) => a + b);
    },
    REMOVE_CART: (state, action) => {
      state.products = state.products.filter(
        (p) => p._id !== action.payload._id
      );
      state.count = state.products.length;
      let proPrice = state.products.map((p) => {
        if (p.disCount !== 0) {
          return p.price * p.number;
        } else {
          const dis = (p.price * p.disCount) / 100;
          console.log(p.price - dis);
          return (p.price - dis) * p.number;
        }
      });
      state.total = proPrice.length > 0 ? proPrice.reduce((a, b) => a + b) : 0;
    },
    CLEAN_CART: (state, action) => {
      state.products = [];
      state.total = 0;
      state.count = 0;
    },
  },
});

export const {
  ADD_CART,
  REMOVE_CART,
  CLEAN_CART,
  DECREASE_CART,
  INCREASE_CART,
} = cartReducer.actions;

export default cartReducer.reducer;
