import { useEffect, useState } from "react";
import GetText from "../../functions/getTexts";
import BigBlog from "../bigBlog/BigBlog";
import BlogCart from "../blogCart/BlogCart";
import BlogHistory from "../blogHistory/BlogHistory";
import "./blogsContent.scss";
import useFetch from "../../functions/useFetch";
import { Helmet } from "react-helmet";

const BlogsContent = () => {
  const { title } = GetText("blogs");
  const [year, setYear] = useState(new Date().getFullYear());
  const [month, setMonth] = useState(new Date().getMonth());
  const [minyear, setMinyear] = useState();

  const { data, error, isFetching } = useFetch(
    `/blog/?year=${year}&month=${month}&newest=true`
  );

  return (
    !isFetching && (
      <div className="blogsContent">
        <Helmet>
          <title>بلاگ</title>
          <meta name="description" content="" />
          <meta name="keywords" content="" />
        </Helmet>
        <div className="bcContainer">
          <h3>{title}</h3>
          <div className="contentsBlogs">
            <div className="top">
              <BlogHistory
                setMonth={setMonth}
                setYear={setYear}
                month={month}
                year={year}
              />
              {data.length > 0 && <BigBlog data={data[0]} />}
            </div>
            <div className="bottom">
              {data.map((p, i) => {
                return <BlogCart data={p} key={p._id} />;
              })}
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default BlogsContent;
