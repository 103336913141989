import { useTranslation } from "react-i18next";
import "./bigBlog.scss";
import { Link } from "react-router-dom";
import GetText from "../../functions/getTexts";

const BigBlog = ({ data }) => {
  const { img, contents, topic } = data;
  const lan = useTranslation().i18n.language;
  const { dir, see } = GetText("slider");
  const baseUrl = process.env.REACT_APP_IMAGES_SOURCE;
  return (
    <div className={dir ? "bigBlog" : "bigBlog-ltr"}>
      <h1 className="title"> {topic[lan]} </h1>
      <div className="bbContent">
        <div className="left">
          <p>{contents[0].text[lan]}</p>
          <Link to={"/blogs/" + data._id}>
            <div className="seemore">{see}</div>
          </Link>
        </div>
        <div className="right">
          <img src={baseUrl + img[lan]} alt="" />
        </div>
      </div>
    </div>
  );
};

export default BigBlog;
