import moment from "jalali-moment";
import "./message.scss";
import DoneAllOutlinedIcon from "@mui/icons-material/DoneAllOutlined";
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";

const Message = ({ m, own, read }) => {
  return (
    <div className={own ? "yours" : "supports"}>
      {m}
      <div
        className="time"
        style={{ display: "flex", gap: "15px", alignItems: "center" }}
      >
        {moment(Date.now()).format("hh:mm")}
        {own && (
          <div>
            {read ? (
              <DoneAllOutlinedIcon style={{ color: "white" }} />
            ) : (
              <DoneOutlinedIcon />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Message;
