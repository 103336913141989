import "./productCart.scss";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
import SearchIcon from "@mui/icons-material/Search";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import GetText from "../../functions/getTexts";
import { useDispatch } from "react-redux";
import { ADD_CART } from "../../redux/cartRedux";
import { toast } from "react-toastify";

const ProductCart = ({ width, details, dir }) => {
  const array = ["brown", "green", "orange"];
  const [liked, setLiked] = useState(false);
  const [productNumber, setProductNumber] = useState(1);
  const dispatch = useDispatch();

  //ADD TO CART BT CART ICON
  const addToCart = () => {
    details.count >= productNumber
      ? dispatch(
          ADD_CART({
            ...details,
            number: productNumber,
          })
        )
      : toast.error("درخواست شما بیشتر از حد مجاز است!");
  };

  const { name, price, disCount, img, description, more } = details;

  const calculateDiscount = (price, dis) => {
    return price - (price * dis) / 100;
  };
  const baseurl = process.env.REACT_APP_IMAGES_SOURCE;
  const { pCart } = GetText("productsContent");

  const { i18n } = useTranslation();
  const lan = i18n.language;
  return (
    <div className="productCart">
      <div className="prcContainer">
        <div className="imgContainer">
          <img src={baseurl + img[lan]} alt="" />
          <div className="imgHover">
            <div className="icons">
              {!liked ? (
                <FavoriteBorderIcon
                  onClick={() => setLiked(true)}
                  style={{ color: "white" }}
                  fontSize="large"
                />
              ) : (
                <FavoriteIcon
                  style={{ color: "red" }}
                  onClick={() => setLiked(false)}
                  fontSize="large"
                />
              )}
              <Link to={`/product/${details._id}`}>
                <SearchIcon style={{ color: "white" }} fontSize="large" />
              </Link>
            </div>
          </div>
          {disCount > 0 && <div className="dismark">{disCount}%</div>}
        </div>
        <div className="details">
          <div className="name-price">
            <div className="productname">{name[lan]}</div>
            <div className="priceDetail">
              <div className="price">
                {calculateDiscount(price, disCount).toLocaleString()}
              </div>
              {disCount > 0 && (
                <div className="dis">{price.toLocaleString()}</div>
              )}
            </div>
          </div>
          <p className="desc" style={{ textAlign: !dir && "left" }}>
            {description[lan]}
          </p>
          <div className="add-more">
            <div className="add">
              <ShoppingCartIcon
                className="icon"
                fontSize="small"
                onClick={addToCart}
              />
            </div>
            <Link
              to={"/product/" + details._id}
              className="seeMore-btn"
              style={{ textDecoration: "none" }}
            >
              {pCart.more}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductCart;
