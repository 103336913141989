import { Outlet } from "react-router-dom";
import Banner from "../componnts/banner/Banner";
import Navbar from "../componnts/navbar/Navbar";
import Footer from "../componnts/footer/Footer";

const StaticLayout = () => {
  return (
    <div>
      <Navbar />
      {/* <Banner /> */}
      <Outlet />
      <Footer />
    </div>
  );
};

export default StaticLayout;
