import "./slider.scss";
import { sliderDate } from "../../data/data";
import EastIcon from "@mui/icons-material/East";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { useEffect, useRef, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import GetText from "../../functions/getTexts";
import useFetch from "../../functions/useFetch";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { ADD_CART } from "../../redux/cartRedux";

const Slider = () => {
  const [index, setIndex] = useState(0);
  const sliderRef = useRef();
  const [slideCount, setSlideCount] = useState(5);
  const [productNumber, setProductNumber] = useState(1);
  const dispatch = useDispatch();

  //ADD TO CART BT CART ICON
  const addToCart = (p) => {
    p.count >= productNumber
      ? dispatch(
          ADD_CART({
            ...p,
            number: productNumber,
          })
        )
      : toast.error("درخواست شما بیشتر از حد مجاز است!");
  };

  window.onscroll = () => {
    setSlideCount(Math.floor(window.innerWidth / 380));
    return () => {};
  };

  const { textbtn, see, dir } = GetText("slider");

  const handleClick = (direction) => {
    if (direction == "right") {
      setIndex(index < data.length - slideCount ? index + 1 : index);
    } else {
      setIndex(index > 0 ? index - 1 : index);
    }
  };
  useEffect(() => {
    if (sliderRef.current !== undefined) {
      sliderRef.current.style.transform = `translateX(${index * 380}px)`;
    }
  }, [index]);

  const { data, error, isFetching } = useFetch("/product/findsample");

  const baseUrl = process.env.REACT_APP_IMAGES_SOURCE;
  const lan = useTranslation().i18n.language;

  return (
    !isFetching &&
    !error && (
      <div className="slider">
        <KeyboardArrowRightIcon
          className="row right"
          onClick={() => handleClick("right")}
          style={{
            display:
              Math.abs(index - data.length) == slideCount ? "none" : "flex",
          }}
        />
        <KeyboardArrowLeftIcon
          className="row left"
          onClick={() => handleClick("left")}
          style={{
            display: index == 0 ? "none" : "flex",
          }}
        />
        <div className="sliderWaraper" ref={sliderRef}>
          {data?.map((item, i) => {
            return (
              <div className="sliderItem" key={i}>
                <div className="imgContainer">
                  <img src={baseUrl + item.img[lan]} alt="" />
                  <div className="shadow">
                    <div className="icons">
                      <ShoppingCartIcon
                        className="shadowIcon"
                        fontSize="large"
                        onClick={() => addToCart(item)}
                      />
                      <Link to={"/product/" + item._id}>
                        <SearchIcon className="shadowIcon" fontSize="large" />
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="proName">{item.name[lan]}</div>
                <div className="desc" style={{ direction: !dir && "ltr" }}>
                  {item.description[lan]}
                  <Link to={"/product/" + item._id}>
                    <div className="show-more">{see}</div>
                  </Link>
                </div>
              </div>
            );
          })}
        </div>
        <Link to={"/products"}>
          <div className="seeMore-btn">{textbtn} </div>
        </Link>
      </div>
    )
  );
};

export default Slider;
