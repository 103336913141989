import "./searchBox.scss";
import SearchIcon from "@mui/icons-material/Search";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { SearchContext } from "../../context/SearchContext";
import GetText from "../../functions/getTexts";
import { useLocation, useNavigate } from "react-router-dom";

const SearchBox = () => {
  const [show, setShow] = useState(false);

  const { search, dir } = GetText("recipe");
  const lan = useTranslation().i18n.language;

  const isRecipe = useLocation().pathname.split("/")[1];

  const {
    setLiked,
    setRecent,
    catogeries,
    filter,
    setFilter,
    catogery,
    setCatogery,
    searchTopic,
    setSearchTopic,
  } = useContext(SearchContext);
  const navigate = useNavigate();

  const handleSearchFilter = (filter) => {
    setSearchTopic(null);
    setCatogery("null");
    if (filter == "recent") {
      setLiked(false);
      setRecent(true);
    } else {
      setRecent(false);
      setLiked(true);
    }
    isRecipe == "recipe" && navigate("/recipes/search");
  };

  const handleSelectCatogery = (id) => {
    setSearchTopic(null);
    setCatogery(id);
    setLiked(false);
    setRecent(false);
    setShow(false);
    isRecipe == "recipe" && navigate("/recipes/search");
  };

  return (
    <div className={dir ? "searchBox" : "searchBox-ltr"}>
      <div className="title">{search.title}</div>
      <div className="search">
        <SearchIcon
          className="icon"
          fontSize="large"
          onClick={() => navigate("/recipes/search")}
        />
        <input
          type="text"
          placeholder={search.placeholder}
          onChange={(e) => setSearchTopic(e.target.value)}
        />
      </div>
      <div className="filters">
        <div className="filter" style={{ borderLeft: !dir && "none" }}>
          <ArrowDropDownIcon className="icon" onClick={() => setShow(!show)} />
          <span className="titr">{search.type}</span>
          <div className={`meals `} style={{ display: show ? "flex" : "none" }}>
            <div className={`mealContainer ${show && "active"}`}>
              {catogeries.map((c) => (
                <div
                  className="meal"
                  onClick={() => handleSelectCatogery(c._id)}
                >
                  {c.title[lan]}
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="filter">
          <span className="titr" onClick={() => handleSearchFilter("recent")}>
            {search.recent}
          </span>
        </div>
        <div className="filter" style={{ borderLeft: "none" }}>
          <span
            className="titr"
            style={{
              borderLeft: dir ? "none" : "2px solid gray",
              paddingLeft: dir ? "none" : "10px",
            }}
            onClick={() => handleSearchFilter("most")}
          >
            {search.most}
          </span>
        </div>
      </div>
    </div>
  );
};

export default SearchBox;
