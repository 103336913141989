import { LOGIN_FAILURE, LOGIN_START, LOGIN_SUCCESS } from "../redux/userRedux";
import { publicRequest } from "./request";
import { handleError } from "./errorHandling";

export const loginCall = async (dispatch, user) => {
  try {
    dispatch(LOGIN_START());
    const res = await publicRequest.post("/auth/login", user);
    dispatch(LOGIN_SUCCESS(res.data));
  } catch (error) {
    handleError(error);
    dispatch(LOGIN_FAILURE());
  }
};
