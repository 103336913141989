import { useEffect, useRef, useState } from "react";
import "./banner.scss";
import { useTranslation } from "react-i18next";
import { cheackDir } from "../../functions/cheackDir";
import useFetch from "../../functions/useFetch";

const Banner = () => {
  const [index, setIndex] = useState(0);
  const sliderRef = useRef();

  const { t, i18n } = useTranslation();
  const { line1, line2, line3 } = t("banner");

  const { data, error, isFetching } = useFetch(
    "/image/find?page=main&place=productBanner&isSelect=true"
  );

  //FOR SET BANNER'S BACKGROUND WITH IMAGES THAT GOT DATABASE
  useEffect(() => {
    data.length > 0 &&
      (sliderRef.current.style.backgroundImage = `url(${
        process.env.REACT_APP_IMAGES_SOURCE + data[index].link
      })`);
    setTimeout(() => {
      setIndex(index < data?.length - 1 ? index + 1 : 0);
    }, 3000);
  }, [data, index]);

  return (
    !isFetching &&
    !error && (
      <div className="banner">
        <div className="bannerSlider" ref={sliderRef}>
          <div className={`texts ${!cheackDir(i18n.language) && "ltr"}`}>
            <h1> {line1} </h1>
            <h3> {line2}</h3>
          </div>
        </div>
      </div>
    )
  );
};
export default Banner;
