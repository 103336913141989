import { useEffect, useState } from "react";
import { months } from "./data";
import "./blogHistory.scss";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
const BlogHistory = ({ setMonth, month, year, setYear, minyear }) => {
  const currentMonth = new Date().getMonth();

  const cyear = new Date().getFullYear();

  const handleYear = (type) => {
    if (type == "dec") {
      setYear(year > 2023 ? year - 1 : year);
    } else {
      setYear(year == cyear ? year : year + 1);
    }
  };

  

  return (
    <div className="blogHistory">
      <h1>ارشیوها</h1>
      <div className="historyContent">
        <div className="yearCon">
          {year < cyear && (
            <ArrowRightAltIcon
              className="icon"
              onClick={() => handleYear("inc")}
            />
          )}
          <span className="year">{year}</span>
          {year != 2023 && (
            <KeyboardBackspaceIcon
              className="icon"
              onClick={() => handleYear("dec")}
            />
          )}
        </div>
        {months.map((m, i) => {
          
          return (
            <span
              onClick={() => setMonth(i + 1 > currentMonth ? month : i + 1)}
              className={`${i + 1 <= currentMonth && "bold"} + ${
                i + 1 == month && "active"
              }`}
            >
              {m}
            </span>
          );
        })}
      </div>
    </div>
  );
};

export default BlogHistory;
