import { Link, useLocation, useParams } from "react-router-dom";
import { sustabilityNavbar } from "../../data/data";
import "./pollination.scss";
import { polonationHeader } from "./data";
import { generateTags, generateHeader } from "../../functions/generateTags";
import MenuList from "../menuList/MenuList";
import GetText from "../../functions/getTexts";
import { useEffect, useState } from "react";
import { cheackDir } from "../../functions/cheackDir";
import { useTranslation } from "react-i18next";
import useFetch from "../../functions/useFetch";
import { publicRequest } from "../../functions/request";
import { handleError } from "../../functions/errorHandling";
import { Helmet } from "react-helmet";

const Pollonation = () => {
  const [articleData, setArticleData] = useState([]);
  const [headerList, setHeaderList] = useState([]);
  const articleId = useParams().articleId;
  const location = useLocation().pathname?.split("/")[1];

  const cheackWichPage = (location) => {
    if (location == "abouthoney") {
      return "abouthoney";
    }
    return "sustainability";
  };

  const { data, error, isFetching } = useFetch(
    "/article/?artCato=" + cheackWichPage(location)
  );

  useEffect(() => {
    const getArticle = async () => {
      try {
        const res = await publicRequest.get("/article/" + articleId);
        setArticleData(res.data.contents);
      } catch (error) {
        handleError(error);
      }
    };
    articleId !== "first" && getArticle();
  }, [articleId]);

  useEffect(() => {
    data &&
      setHeaderList(
        data?.map((d) => {
          return {
            topic: d.topic,
            articleId: d._id,
          };
        })
      );
  }, [data]);

  useEffect(() => {
    data.length > 0 && setArticleData(data[0].contents);
    data.length == 0 && setArticleData([]);
  }, [data.length]);

  const { dir, ...other } = GetText(cheackWichPage(location));
  const lan = useTranslation().i18n.language;

  return (
    <div className={dir ? "pollination" : "pollination-ltr"}>
      <Helmet>
        <title>{articleData[0]?.title[lan]}</title>
        <meta name="description" content="" />
        <meta name="keywords" content="" />
      </Helmet>
      <div className="bannerContainer">{generateHeader(polonationHeader)}</div>
      <div className="poContainer">
        <MenuList list={headerList} mwidth={true} />
        <div className="poContent">
          {articleData?.map((item, i) => (
            <div key={i}> {generateTags(item, lan)}</div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Pollonation;
