import "./aboutBeekiping.scss";
import EastIcon from "@mui/icons-material/East";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import useFetch from "../../functions/useFetch";
import { publicRequest } from "../../functions/request";

const AboutBeekiping = () => {
  const [img, setImg] = useState(null);
  const { t } = useTranslation();
  const { title, description, showVideo } = t("aboutbeekiping");

  useEffect(() => {
    const getImg = async () => {
      try {
        const res = await publicRequest.get(
          "/image/find/?page=main&place=beekiping&isSelect=true"
        );
        setImg(res.data[0]);
      } catch (error) {
        console.log(error);
      }
    };
    getImg();
  }, []);

  return (
    <div className="beekiping">
      <div className="beekipingContainer">
        <div className="text">
          <div className="title"> {title}</div>
          <div className="textContent">{description}</div>
          <div className="beekiping-btn">
            {/* <EastIcon className="arrow-icon" /> */}
            <Link to={"/newsroom/videos"} state={"اخبار"}>
              <span> {showVideo}</span>
            </Link>
          </div>
        </div>
        <div className="img">
          <img
            src={process.env.REACT_APP_IMAGES_SOURCE + img?.link}
            alt="beekiping"
          />
        </div>
      </div>
    </div>
  );
};

export default AboutBeekiping;
