import { Link } from "react-router-dom";
import GetText from "../../functions/getTexts";
import "./anouncment.scss";

const Announcment = () => {
  const { text, companyname, by, aiCo } = GetText("annoncment");
  return (
    <div className="anouncment">
      <p>
        {/* <span >{text}</span> */}
        <a href="http://vira-sk.ir/طراحی-سایت" target="_blank">
          {text}
        </a>
        <span style={{ fontWeight: "normal" }}>{by}</span>
        <a href="http://vira-sk.com" target="_blank">
          {aiCo}
        </a>
        <span style={{ fontWeight: "normal" }}>{companyname} </span>
      </p>
    </div>
  );
};

export default Announcment;
