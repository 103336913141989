import "./product.scss";
import Navbar from "../../componnts/navbar/Navbar";
import Footer from "../../componnts/footer/Footer";
import ProductContent from "../../componnts/productContent/ProductContent";
import useFetch from "../../functions/useFetch";
import { useLocation, useParams } from "react-router-dom";

const Product = () => {
  return (
    <div className="product">
      <Navbar />
      <ProductContent />
      <Footer />
    </div>
  );
};

export default Product;
