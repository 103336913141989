import { useTranslation } from "react-i18next";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import "./searchItem.scss";
import GetText from "../../functions/getTexts";
import { Link } from "react-router-dom";
import { ADD_CART } from "../../redux/cartRedux";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";

const SearchItem = ({ data, setSearched }) => {
  const { img, name, description, price, count, _id } = data;
  const lan = useTranslation().i18n.language;
  const baseUrl = process.env.REACT_APP_IMAGES_SOURCE;

  const dispatch = useDispatch();

  //ADD TO CART BT CART ICON
  const addToCart = (p) => {
    p.count >= 1
      ? dispatch(
          ADD_CART({
            ...p,
            number: 1,
          })
        )
      : toast.error("درخواست شما بیشتر از حد مجاز است!");
  };
  const { status, dir } = GetText("searchItem");

  return (
    <div className={dir ? "searchIem" : "searchIem-ltr"}>
      <Link to={"/product/" + _id} onClick={() => setSearched("")}>
        <div className="imgDEtails">
          <img src={img ? baseUrl + img[lan] : "/images/noimg.png"} alt="" />
          <div className="details">
            <div className="name">{name[lan]}</div>
            <div className="price">{price}</div>
          </div>
        </div>
      </Link>
      <div className="add-dis">
        <div className={`status ${count == 0 && "no"}`}>
          {count > 0 ? status.yes : status.no}
        </div>
        <AddShoppingCartIcon
          className="addsearchicon"
          onClick={() => addToCart(data)}
        />
      </div>
    </div>
  );
};

export default SearchItem;
