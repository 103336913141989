import "./recipes.scss";
import RecipesHead from "../../componnts/recipesHead/RecipesHead";
import Footer from "../../componnts/footer/Footer";
import Navbar from "../../componnts/navbar/Navbar";
import RecipesSearchBox from "../../componnts/recipesSearchBox/RecipesSearchBox";
import RecipesMost from "../../componnts/recipesMost/RecipesMost";
import { Helmet } from "react-helmet";

const Recipes = () => {
  return (
    <div className="recipes">
      <Helmet>
        <title>دستور پخت</title>
        <meta name="description" content="" />
        <meta name="keywords" content="" />
      </Helmet>
      <Navbar />
      <RecipesHead />
      <RecipesSearchBox />
      <RecipesMost />
      <Footer />
    </div>
  );
};

export default Recipes;
