import React from "react";
import Navbar from "../../componnts/navbar/Navbar";
import Footer from "../../componnts/footer/Footer";
import Locator from "../../componnts/locator/Locator";

const LocatorPage = () => {
  return (
    <div>
      <Navbar />
      <Locator />
      {/* <Footer /> */}
    </div>
  );
};

export default LocatorPage;
