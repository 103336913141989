export const builder = {
  fa: {
    name: "مهندس علی رضا کردیان",
    about: `
    علیرضا کردیان، بنیان‌گذار و صاحب امتیاز برند «کندو کندی»، یکی از پیشگامان صنعت تولید محصولات ارگانیک در ایران است. او فعالیت‌های خود را از اوایل دهه 60 شمسی آغاز کرد و با تکیه بر دانش و تجربه خود، به تولید محصولاتی با کیفیت و طبیعی پرداخت.
کردیان با هدف ارتقاء سلامتی و ترویج مصرف محصولات ارگانیک، به ویژه در زمینه عسل، لواشک و پنیر، گام‌های مؤثری برداشت. او با استفاده از روش‌های سنتی و مدرن، توانسته است محصولاتی با طعم و کیفیت عالی تولید کند که به سرعت در بازار محبوبیت پیدا کردند.
برند کندو کندی به عنوان نماد کیفیت و اصالت در تولید محصولات ارگانیک شناخته می‌شود و کردیان با تعهد به اصول پایداری و محیط زیست، همواره در تلاش است تا بهترین‌ها را برای مشتریان خود فراهم کند. تلاش‌های او نه تنها به توسعه صنعت غذایی ایران کمک کرده، بلکه فرهنگ مصرف محصولات ارگانیک را نیز در جامعه ترویج داده است.
    
    `,
  },
  ar: {
    name: "مهندس علی رضا کردیان",
    about: `
    علیرضا کردیان، بنیان‌گذار و صاحب امتیاز برند «کندو کندی»، یکی از پیشگامان صنعت تولید محصولات ارگانیک در ایران است. او فعالیت‌های خود را از اوایل دهه 60 شمسی آغاز کرد و با تکیه بر دانش و تجربه خود، به تولید محصولاتی با کیفیت و طبیعی پرداخت.
کردیان با هدف ارتقاء سلامتی و ترویج مصرف محصولات ارگانیک، به ویژه در زمینه عسل، لواشک و پنیر، گام‌های مؤثری برداشت. او با استفاده از روش‌های سنتی و مدرن، توانسته است محصولاتی با طعم و کیفیت عالی تولید کند که به سرعت در بازار محبوبیت پیدا کردند.
برند کندو کندی به عنوان نماد کیفیت و اصالت در تولید محصولات ارگانیک شناخته می‌شود و کردیان با تعهد به اصول پایداری و محیط زیست، همواره در تلاش است تا بهترین‌ها را برای مشتریان خود فراهم کند. تلاش‌های او نه تنها به توسعه صنعت غذایی ایران کمک کرده، بلکه فرهنگ مصرف محصولات ارگانیک را نیز در جامعه ترویج داده است.
    
    `,
  },
  en: {
    name: "Mr Alireza Kurdian",
    about: `
    Alireza kurdian, the founder and franchisee of the "Candoo Kandi" brand, is one of the pioneers of the organic products production industry in Iran. He started his activities in the early 60s and based on his knowledge and experience, he started producing quality and natural products.
With the aim of improving health and promoting the consumption of organic products, Kurdian took effective steps, especially in the field of honey, lavash and cheese. By using traditional and modern methods, he has been able to produce products with excellent taste and quality, which quickly gained popularity in the market.
Candoo Candu brand is known as a symbol of quality and authenticity in the production of organic products, and Keridian is committed to the principles of sustainability and the environment, and is always trying to provide the best for its customers. His efforts have not only helped the development of Iran's food industry, but also promoted the culture of consuming organic products in the society.
    `,
  },
  ru: {
    name: "Mr Alireza Kurdian",
    about: `
    Alireza kurdian, the founder and franchisee of the "Candoo Kandi" brand, is one of the pioneers of the organic products production industry in Iran. He started his activities in the early 60s and based on his knowledge and experience, he started producing quality and natural products.
With the aim of improving health and promoting the consumption of organic products, Kurdian took effective steps, especially in the field of honey, lavash and cheese. By using traditional and modern methods, he has been able to produce products with excellent taste and quality, which quickly gained popularity in the market.
Candoo Candu brand is known as a symbol of quality and authenticity in the production of organic products, and Keridian is committed to the principles of sustainability and the environment, and is always trying to provide the best for its customers. His efforts have not only helped the development of Iran's food industry, but also promoted the culture of consuming organic products in the society.
    `,
  },
};
